import React, { useEffect, useState } from "react";
import EwalletService from "../../../service/ewallet/Ewallet";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { CurrencyDecimalFormat } from "../../helper";

//service
import AppInfoservice from "../../../service/common/Appinfo";

//style
import { Row, Col } from "react-bootstrap";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.service = new EwalletService();

    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()} , ${current.getHours()}:${current.getMinutes()}`;
    // const time = current.getHours() + ':' + current.getMinutes();

    this.state = {
      earnings: {},
      loading: true,
      date: date,
    };
  }

  exportDatas(type) {
    this.service.exportData(type).then((res) => {
      if (res.status) {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
          earnings: res.data,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          loading: true,
        }));
      }
    });
  }

  componentDidMount() {
    //this.exportDatas("print")
  }

  render() {
    return (
      <div style={{ padding: 60 }}>
        <Row>
          <Col>{this.state.date}</Col>
          <Col>
            <h5 style={{ textAlign: "center" }}>Purchase Report</h5>
          </Col>
        </Row>

        <div ref={this.ref}>
          <Row style={{ marginBottom: 50 }}>
            <Col span={8}>
              <img src={this.props.company.logo} alt="Logo" />
              <div>COMPANY NAME : {this.props.company.comapny_name}</div>
              <div>COMPANY ADDRESS : {this.props.company.company_address}</div>
              <div>TELEPHONE : {this.props.company.phone}</div>
              <div>EMAIL : {this.props.company.email}</div>
            </Col>
          </Row>

          {this.props.data.data.length > 0 && (
            <Table striped hover>
              <thead>
                <tr>
                  <th>{this.props.t("Common.category")}</th>
                  <th>{this.props.t("Common.totalAmount")}</th>
                  <th>{this.props.t("Common.tax")}</th>
                  <th>{this.props.t("Common.serviceChange")}</th>
                  <th>{this.props.t("Common.amountPayable")}</th>
                  <th>{this.props.t("Common.transactionDate")}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.data.map((obj, index) => {
                  console.log('objeee',obj)
                  return (
                    <tr key={index}>
                      <td>{obj.category}</td>
                     
                      {/* <td>{obj.amount_withCurrency}</td> */}
                      <td>
                        {this.props.Currency.currentCurr}{" "}
                        {CurrencyDecimalFormat(
                          obj.amount * this.props.Currency.value,
                          this.props.Currency.precision
                        )}
                      </td>
                      <td>{obj.tax}</td>
                      <td>{obj.serviceCharge}</td>
                      <td>{obj.amountPayable}</td>
                      <td>{obj.transaction_date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    );
  }
}

const PurchaseReportPrintView = React.forwardRef((props, ref) => {
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();

  const [companyDetails, setCompanyDetails] = useState({});

  const service = new AppInfoservice();

  useEffect(() => {
    service.Appinfo().then((res) => {
      if (res.status) {
        setCompanyDetails((prevState) => ({
          ...prevState,
          logo: res.data.company_info.login_logo,
          comapny_name: res.data.company_info.company_name,
          company_address: res.data.company_info.company_address,
          phone: res.data.company_info.phone,
          email: res.data.company_info.email,
        }));
      }
    });
  }, []);
  return (
    <ComponentToPrint
      ref={ref}
      data={props}
      company={companyDetails}
      Currency={Currency}
      t={t}
    />
  );
});
export default PurchaseReportPrintView;

import React from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CurrencyDecimalFormat } from "../../helper";
import style from "./payin.module.scss";
function RejectedTable(props) {
  const { t } = useTranslation(["translation", "payout"]);
  const Currency = useSelector((state) => state.curr);
  const columns = [
    {
      name: t("payout.rejectedDate"),
      selector: (row) => row.requested_date,
      //   width : "30%"
    },
    {
      name: t("Common.amount"),
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <span className={`${style.amount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.amount * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },
    {
      name: t("payout.requestedDate"),
      selector: (row) => row.rejected_date,
      sortable: true,
    },
  ];

  const handlePageChange = (inPage) => {
    props.paginationchange(inPage, props.ContentData.perPage);
  };
  const handlePerRowsChange = (perPage, inPage) => {
    props.paginationchange(inPage, perPage);
  };
  const handleOnSort = ({ selector }, sortDirection) => {
    props.paginationchange(
      props.ContentData.inPage,
      props.ContentData.perPage,
      selector,
      sortDirection
    );
  };
  return (
    <DataTable
      columns={columns}
      data={props.ContentData.data}
      highlightOnHover
      progressPending={props.ContentData.pending}
      progressComponent={<div>Loading</div>}
      persistTableHead
      noHeader
      pagination
      paginationServer
      responsive
      paginationTotalRows={props.ContentData.totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      noDataComponent={t("Common.noDataRecordsToDisplay")}
      //onSort={handleOnSort}
    />
  );
}

export default RejectedTable;

import { BASE_URL,DEFAULT_KEY} from "../../configuration/apiconfig"
import UserStore from "../../store/UserStore"

export default class ReportService{
    API_KEY=localStorage.getItem('apikey')
    async getCommissionReport(start=0,length=10,startDate='',endDate=''){
        const response=await fetch(`${BASE_URL}report/commissionReport?start=${start}&length=${length}&start_date=${startDate}&end_date=${endDate}`,
        {
            method:'GET',
            headers:{
                'Accept'        :'application/json',
                'Content-TYpe'  :'application/json',
                'api-key'       :'1066_',
                'access-token'  :UserStore.key
            }
        })
        const result=await response.json();
        return result;
    }
}
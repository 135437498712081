import React from 'react'
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import StatementTable from './StatementTable';
import WalletTable from './WalletTable';
import EarningsTable from './EarningsTable';
import HistoryTable from './HistoryTable';
import style from "./ewallet.module.scss";
import Tabs from "react-responsive-tabs";
const stylees = {
    paddingTop: "10px",
    fontFamily: "system-ui",
  };
function Content({
    tableData,
    changeHandler,
    paginationHandler,
    repurchase_status,
    loader, }) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const { t } = useTranslation();
    const [tabs, setTabs] = useState([
        "statement",
        // "transferHistory",
        "purchaseWallet",
        "earning",
    ]);
    useEffect(() => {
        if (repurchase_status !== "yes") {
          setTabs(["statement", "earning"]);
        }
      }, [repurchase_status]);

      const getTabs = () => {
        return tabs.map((tab, index) => ({
          title: t("Common." + tab),
          getContent: () => getContent(tab),
          /* Optional parameters */
          key: tab,
          tabClassName: style.TabClass,
          panelClassName: style.TabPanel,
          // tabsWrapperClass : 'safdsadfads'
        }));
      };

      const getContent = (contentId) => {
        if (contentId === "statement") {
          return (
            <div className={style.dataTable} style={stylees}>
              <StatementTable
                ContentData={tableData.statement}
                paginationchange={paginationHandler.statement}
              />
            </div>
          );
        } else if (contentId === "transferHistory") {
          return (
            <div className={style.dataTable} style={stylees}>
              <HistoryTable
                loader={loader}
                ContentData={tableData.history}
                paginationchange={paginationHandler.history}
              />
            </div>
          );
        } else if (contentId === "purchaseWallet") {
          return (
            <div className={style.dataTable} style={stylees}>
              <WalletTable
                ContentData={tableData.wallet}
                paginationchange={paginationHandler.wallet}
              />
            </div>
          );
        } else if (contentId === "earning") {
          return (
            <div className={style.dataTable} style={stylees}>
              <EarningsTable
                loader={loader}
                ContentData={tableData.earnings}
                paginationchange={paginationHandler.earnings}
                // exportData={exportData}
              />
            </div>
          );
        }
      };
    return (
        <div className={style.summary}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_tabContent}`
            : `${style.tabContent}`
        }`}
      >
        <Tabs
          items={getTabs()}
          transform={true}
          transformWidth={734}
          onChange={changeHandler}
          selectedTabKey={tableData.key}
        />
      </div>
    </div>
    )
}

export default Content
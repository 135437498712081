import React from "react";
import style from "./support.module.scss";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import AlertBs from "../common/AlertBs";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Profilepic from '../../../assets/images/nophoto/no_photo.jpg'
  
const TicketDetailsContent = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  const backButtonClick = () => {
    history.goBack();
  };
  const replies = props.replies.map((reply, key) => {
    console.log(`reply.attachments ${reply.attachments}`)
    return (
      <div key={key} className={style.msgContainer}>
        <div className={style.avatar}>
          <img
            className={style.msgAvatar}
            alt="avatar"
            src={reply.profile_pic == "" ? Profilepic :reply.profile_pic}
          />
        </div>
        <div>
          <div className={style.messages}>
            <p>{reply.message}</p>
            {reply.attachments === "" ? "":(
              <a href={reply.attachments} target="_blank" rel="noreferrer">
                <img src={reply.attachments} alt="" />
              </a>
            )}
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className={style.container}>
      <div style={{ textAlign: "right" }}>
        <Button
          className={style.btnGrop}
          variant="primary"
          onClick={backButtonClick}
        >
          <i className="fa fa-backward"></i>
          {t("Button.go_to_support_center")}
        </Button>
      </div>
      <Row>
        <Col>
          <AlertBs {...props.alert} close={props.closeAlert} />
          <Row>
            <Col md="7">
              <Card>
                <Card.Body>
                  <div className={style.msgContainerBase}>{replies}</div>

                  <div className={style.chatMsgAttach}>
                    <div className={style.chatMsg}>
                      <Form.Group className={style.formGroup}>
                        <Form.Control
                          value={props.new_message}
                          as="textarea"
                          rows={3}
                          placeholder={t("ticket.message")}
                          className={style.formControl}
                          onChange={props.addMessage}
                        />
                      </Form.Group>
                      <button
                        type="button"
                        className={style.chatBtn}
                        onClick={props.saveMessage}
                      >
                        <i className={`fa fa-paper-plane  ${style.icon}`}></i>
                      </button>
                    </div>

                    <div className={style.chatAttach}>
                      <div
                        data-provides="fileupload"
                        className={style.fileuploadNew}
                      >
                        <span className={style.btnFile}>
                          <i className="fa fa-paperclip"></i>
                          {props.new_attachment ? (
                            <span className={style.fileuploadNew}>
                              {" "}
                              {t("ticket.change")}
                            </span>
                          ) : (
                            <span className={style.fileuploadNew}>
                              {" "}
                              {t("ticket.attachFile")}
                            </span>
                          )}
                          <input
                            type="file"
                            id="upload_doc"
                            name="upload_doc"
                            onChange={props.addAttachment}
                          />
                        </span>
                        {props.new_attachment && (
                          <div>
                            <span className={style.fileuploadPreview}>
                              {props.new_attachment.name}
                            </span>
                            <span
                              className={style.closeAttach}
                              onClick={props.closeAttachment}
                            >
                              {" "}
                              ×{" "}
                            </span>
                          </div>
                        )}
                      </div>
                      <p
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_fileuploadInfo}`
                            : `${style.fileuploadInfo}`
                        }`}
                      >
                        <font>{t("ticket.allowedTypes")}</font>
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="5">
              <table
                st-table="rowCollectionBasic"
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_tableChat}`
                    : `${style.tableChat}`
                }`}
              >
                <tbody>
                  <tr>
                    <td>{t("ticket.ticketID")}</td>
                    <td>{props.ticket_id}</td>
                  </tr>
                  <tr>
                    <td>{t("ticket.ticketStatus")}</td>
                    <td>{props.details.status}</td>
                  </tr>
                  <tr>
                    <td>{t("ticket.createdOn")}</td>
                    <td>{props.details.created_date}</td>
                  </tr>
                  <tr>
                    <td>{t("ticket.updatedDate")}</td>
                    <td>{props.details.updated_date}</td>
                  </tr>
                  <tr>
                    <td>{t("ticket.category")}</td>
                    <td>{props.details.category}</td>
                  </tr>
                  <tr>
                    <td>{t("ticket.priority")}</td>
                    <td>{props.details.priority}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default TicketDetailsContent;

import React from 'react';
import {Form} from 'react-bootstrap';
import style from './registration.module.scss';
import {Paneltilte,Buttons} from './';
import FormsControl from './FormsControl';
// import {useTranslation} from 'react-i18next';

function LoginInformation(props) {
    // const {t} = useTranslation()
    //change handler

   
    const changehandler = (e) =>{
        props.ChangeHandler(e,'LoginInfo')
    }
    return (
        <div className={style.SponsorPackage}>
            <Paneltilte 
                tilte="loginInformation"
            />
            <Form onSubmit={(e)=>{props.submit(e,"",'LoginInfo')}}>
            {
                props.data.map((field,index)=>{
                    {console.log("field=======",props.policy)}
                    return <FormsControl
                    key={index} 
                    data={field}
                    change={changehandler} 
                    policy={props.policy}
                    validation={field.validation}
                    blurhandler={props.blurhandler}
                    openModal={props.openModal}
                    /> 
                })
            }
            <Buttons 
                prev={true}
                prevButtonClick={props.prevButtonClick}
                next={props.enableFinish}
            />
            </Form>
        </div>
    )
}

export default LoginInformation

import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { FormInputControl } from '../common'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CurrencyDecimalFormat } from '../../helper'
import { isNumber } from '../../validation'
import GadgetWalletservice from '../../../service/Gadgetwallet/Gadgetwallet'
import { AlertMessage } from '../common'

function ModalForm(props) {
    const service = new GadgetWalletservice()
    const Currency = useSelector((state) => state.curr)
    const [State, setState] = useState({
        Amount: "",
        AvailiableAmount: props.balance * Currency.value,
        password: "",
        IsError: false,
        notifyMessage: "",
        notifyType: "",
        header: "Error!",
        validated: false,
        FormError: {
            Amount: "",
            AvailiableAmount: "",
            password: ""
        },
        loader: false
    })


    const { FormError, IsError, notifyMessage, notifyType, header } = State
    console.log('modalfrom', props)
    const { t } = useTranslation();
    const textChangeHandler = (e) => {
        console.log('e', e.target.value)
        e.preventDefault();
        const { name, value } = e.target
        FormError.password = ""
        FormError.Amount = ""
        setState((prev) => ({
            ...prev,
            validated: false,
            [name]: value
        }))

    }
    const notifyDissmissed = () => {
        setState((prev) => ({
            ...prev,
            IsError: false,
            notifyMessage: "",
        }));
        if (notifyType === "success") {
            props.handleClose();
        }
    };
    const submitForm = (e) => {
        e.preventDefault()
        let valid = true
        const validamount = validation("amount") ?? false
        const validpassword = validation("password") ?? false
        if (validamount && validpassword) {
            let formdata = {
                amount: State.Amount,
                pswd: State.password
            }
            // setState((prev) => ({
            //     ...prev,
            //     loader: true
            // }))
            service.CashwalletFundTransfer(formdata).then((res) => {
                if (res.status) {
                    setState((prev) => ({
                        ...prev,
                        Amount: "",
                        password: "",
                        FormError,
                        validated: !valid,
                        IsError: true,
                        notifyMessage: t("ewallet.fundTransferSuccessfully"),
                        notifyType: "success",
                        header: t("Common.success"),
                        loader: false,
                    }));
                    props.submitSuccess()
                } else {
                    let error = ""
                    switch (res.error.code) {
                        case 1015:
                            FormError.password = t("validation.invalid", {
                                field: "transactionPassword",
                            });
                            error = t("validation.invalid", { field: "transactionPassword" });
                            break;
                        case 406:
                            error = "Not Acceptable";
                            break;
                        case 1004:
                            Object.keys(res.error.fields).map((key) => {
                                if (key.substr(key.length - 4) !== "_err") {
                                    error = res.error.fields[key];
                                    switch (key) {
                                        case "pswd":
                                            FormError.password = error;
                                        case "amount":
                                            FormError.Amount = error;
                                            break;
                                        default:
                                            valid = true;
                                            break;
                                    }
                                }
                                return null;
                            });
                            break;
                        default:
                            break;
                    }
                    setState((prev) => ({
                        ...prev,
                        FormError,
                        validated: true,
                        IsError: true,
                        notifyMessage: error,
                        notyfiType: "danger",
                        header: t("Common.error"),
                        loader: false,
                    }));
                }
            })
        } else {
            setState((prev) => ({
                ...prev,
                FormError,
                validated: !valid,
                IsError: true,
                notyfiType: "danger",
                header: t("Common.error"),
                loader: false,
            }));
        }
    }

    const validation = (name) => {
        const values = State
        let valid = true
        let error = ""
        switch (name) {
            case "amount":
                if (values.Amount == "") {
                    valid = false
                    FormError.Amount = t("validation.enterAmount")
                    error = t("validation.enterAmount")
                } else if (!isNumber(values.Amount)) {
                    valid = false
                    FormError.Amount = t("validation.onlyDigitsAllowed")
                    error = t("validation.onlyDigitsAllowed")
                } else if (values <= 0) {
                    valid = false
                    FormError.Amount = t("validation.valueGreaterThan0")
                    error = t("validation.valueGreaterThan0")

                } else if (
                    parseInt(values.Amount) >
                    props.balance * Currency.value
                ) {
                    valid = false;
                    FormError.Amount = t("validation.insufficientBalance");
                    error = t("validation.insufficientBalance");
                } else {
                    FormError.Amount = "";
                    valid = true;
                }
                break;
            case "password":
                if (values.password.length === 0) {
                    valid = false
                    FormError.password = t("validation.transactionPassword")
                    error = t("validation.transactionPassword")
                }
                break;
            default:
                valid = true
        }
        if (!valid && error) {
            setState((prev) => ({
                ...prev,
                notifyMessage: t("validation.checkValueYouHaveSubmitted"),
            }));
        }
        setState((prev) => ({
            ...prev,
            FormError,
            validated: !valid,
        }));
        return valid;

    }
    return (
        <div>
            <Form onSubmit={submitForm} noValidate>
                <FormInputControl
                    label={t("Common.amount")}
                    groupId="EwalletAmount"
                    isPrepend={true}
                    isRequired={true}
                    error={FormError.Amount}
                    inputProps={{
                        name: "Amount",
                        value: State.Amount,
                        onChange: textChangeHandler,
                        placeholder: t("Common.amount"),
                    }}
                />
                <FormInputControl
                    label={t("Common.availableAmount")}
                    groupId="EwalletAvailableAmount"
                    isPrepend={true}
                    isRequired={true}
                    error={FormError.Amount}
                    inputProps={{
                        name: "AvailableAmount",
                        onChange: textChangeHandler,
                        placeholder: t("Common.availableAmount"),
                        disabled: true,
                        value: CurrencyDecimalFormat(
                            State.AvailiableAmount,
                            Currency.precision
                        ),
                    }}
                />
                <FormInputControl
                    label={t("Common.transactionPassword")}
                    isRequired={true}
                    error={{
                        error: FormError.password,
                    }}
                    inputProps={{
                        name: "password",
                        type: "password",
                        value: State.password,
                        placeholder: t("Common.transactionPassword"),
                        onChange: textChangeHandler,
                    }}
                />
                <Button variant="info" type="submit" disabled={State.loader}>
                    {State.loader ? (
                        <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />
                    ) : (
                        ""
                    )}
                    {t("Button.submit")}
                </Button>
                {IsError && (
                    <AlertMessage
                        message={notifyMessage}
                        dismiss={notifyDissmissed}
                        type={notifyType}
                        header={header}
                        show={IsError}
                    />
                )}
            </Form>
        </div>
    )
}

export default ModalForm
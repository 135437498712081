import React from 'react'
import _ from "lodash";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CurrencyDecimalFormat } from '../../helper';
import * as FileSaver from "file-saver";
import style from "./invoice.module.scss";
import * as XLSX from "xlsx";
import { Button,Card } from 'react-bootstrap';
import { useRef } from 'react';
import PurchaseReportPrintView from './PurchaseReportPrintView';
import ReportTable from './ReportTable';
import DatePickers from "../common/DateRangePicker";
import ReactToPrint from "react-to-print";
function Commissionreport(props) {
  console.log('commisionreport',props)
    const { t } = useTranslation();
    const componentRef = useRef();
    const Currency = useSelector((state) => state.curr)
    const ExportToExcel = (apiData, fileName, type) => {
        var copiedData = _.cloneDeep(apiData)
        // copiedData.map((data, index) => {
        //     if (data) {
        //         data.payment_method = t(`Common.${data.payment_method}`);
        //         data.amount =
        //             Currency.currentCurr +
        //             CurrencyDecimalFormat(
        //                 data.amount * Currency.value,
        //                 Currency.precision
        //             );
        //     }
        // })
        // copiedData.map((value) => {
        //     delete value.amount_withCurrency
        // })
        let fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        let fileExtension = ".xlsx";
        if (type === 'excel') {
            const ws = XLSX.utils.json_to_sheet(copiedData)
            XLSX.utils.sheet_add_aoa(ws, [
                [
                    t("Common.category"),
                    t("Common.totalAmount"),
                    t("Common.tax"),
                    t("Common.serviceChange"),
                    t("Common.amountPayable"),
                    t("Common.transactionDate"),

                ]
            ])
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } else if (type === "csv") {
            fileExtension = ".csv";
            let fileType = "text/csv;charset=utf-8";
            const ws = XLSX.utils.json_to_sheet(copiedData);
            XLSX.utils.sheet_add_aoa(ws, [
                [
                    t("Common.category"),
                    t("Common.totalAmount"),
                    t("Common.tax"),
                    t("Common.serviceChange"),
                    t("Common.amountPayable"),
                    t("Common.transactionDate"),
                ],
              ]);
              const csvOutput = XLSX.utils.sheet_to_csv(ws);
              const data = new Blob([csvOutput], { type: fileType });
              FileSaver.saveAs(data, fileName + fileExtension);
        }else if(type==="print"){
            
        }
    }
    const exportData=(type)=>{
        if(type==="excel"){
            ExportToExcel(props.report.data, "Commission Report", type);
        }
        if(type==="csv"){
            ExportToExcel(props.report.data, "Commission Report", type);
        }
        if(type==="print"){
            ExportToExcel(props.report.data, "Commission Report", type);
        }
    }
    return (
        <div className={style.reportContiner}>
        <Card className={style.panel}>
          <Card.Body className={style.panelBody}>
            <div>
              <DatePickers {...props.filter} change={props.changeDate} />
              <Button
                style={{ marginLeft: "10px" }}
                className={`${style.btnPrimary}`}
                onClick={props.submitFilter}
              >
                {t("Button.search")}
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                className={`${style.btnInfo}`}
                onClick={props.resetButton}
              >
                {t("Button.reset")}
              </Button>
  
              <span style={{ float: "right" }} className={style.exportButton}>
                <Button
                  style={{ margin: "10px" }}
                  onClick={() => {
                    exportData("excel");
                  }}
                >
                  <i className="fa fa-file-excel-o"></i>
                  {t("Button.excel")}
                </Button>
                <Button
                  style={{ margin: "10px" }}
                  onClick={() => {
                    exportData("csv");
                  }}
                >
                  <i className="fa fa-file-text-o"></i>
                  {t("Button.CSV")}
                </Button>
                <ReactToPrint
                  //trigger={() => <Button><i className="fa fa-print"></i>{t('Button.print')}</Button>}
                  trigger={() => (
                    <Button style={{ margin: "10px" }}>
                      <i class="fa fa-print"></i>
                      {t("Button.print")}
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              </span>
              <div style={{ display: "none" }}>
                <PurchaseReportPrintView ref={componentRef} {...props.report} />
              </div>
            </div>
            <div className={style.dataTalbes}>
              <ReportTable
                {...props.report}
                paginationchange={props.paginationchange}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    )
}

export default Commissionreport
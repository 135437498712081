import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import companyName from "../../../../store/mobxStore/companyName";
import Forms from "../../../components/auth/Forms";
import ForgotPasswordService from "../../../../service/Auth/ForgotPassowrd";
import ForgotButton from "../../../components/auth/ForgotButton";
import { BASE } from "../../../../configuration/apiconfig";
import { Alert, Form } from "react-bootstrap";
import { AlertMessage } from "../../../components/common";
import { useParams, useHistory } from "react-router-dom";

function ForgotPassword({ t }) {
  const history = useHistory();
  const service = new ForgotPasswordService();

  var captchvalue;

  const [state, setState] = useState({
    userName: "",
    Email: "",
    captcha: "",
    captchvalue: "",
    isLoggedError: false,
    FormError: {
      userName: "",
      Email: "",
      captcha: "",
    },
    btnDisabled: false,
  });
  const [show, setShow] = useState(false);

  const formValid = (formError) => {
    let valid = true;
    Object.values(formError).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  onchange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "userName") {
      const formData = {
        username: value,
      };
      service.checkUsername(formData).then((res) => {
        if (!res.status) {
          if (res.error.code == "1011") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                userName: "This username does not exist",
              },
            }));
          }
        }
      });
    }
  };

  const requiredValid = (value, name) => {
    const FormError = state.FormError;
    switch (name) {
      case "userName":
        FormError.userName = value.length === 0 ? "unamereq" : "";
        break;
      case "Email":
        FormError.Email = value.length === 0 ? "emailreq" : "";
        break;
      case "captcha":
        FormError.captcha = value.length === 0 ? "captchareq" : "";
        break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      FormError,
    }));
  };
  const reloadCaptcha = (userName) => {
    service.getCaptcha(userName).then((res) => {
      let outside = URL.createObjectURL(res);
      setState((prev) => ({
        ...prev,
        captchaImage: outside,
      }));
    });
  };

  const blurhandler = (name) => {
    if (name === "userName") {
      const formData = {
        user_name: state.userName,
      };
      service.checkUsername(formData).then((res) => {
        if (!res.status) {
          if (res.error.code == "1043") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                userName: "Username_not_exists",
              },
            }));
          }
        } else {
          reloadCaptcha(state.userName);
          setState((prevState) => ({
            ...prevState,
            FormError: {
              ...prevState.FormError,
              userName: "",
            },
          }));
        }
      });
    }
    if (name === "Email") {
      const formData = {
        e_mail: state.Email,
        user_name: state.userName,
      };
      service.checkUsermail(formData).then((res) => {
        if (!res.status) {
          if (res.error.code == "1048") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                Email: "email_not_exists",
              },
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            FormError: {
              ...prevState.FormError,
              Email: "",
            },
          }));
        }
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault(); //prevent default
    requiredValid(state.userName, "userName");
    requiredValid(state.Email, "Email");
    requiredValid(state.captcha, "captcha");
    // if (formValid(state.FormError)) {
    //     setShow(true)
    // }
    if (formValid(state.FormError)) {
      if (process.env.REACT_APP_STATUS === "DEMO") {
        setShow(true);
      } else {
        const formData = {
          username: state.userName,
          email: state.Email,
          captcha: state.captcha,
        };
        service.forgot_password(formData).then((res) => {
          if (res.status) {
            showNotify(
              "success",
              "Common.success",
              "validation.captchaSuccess"
            );
            setTimeout(() => {
              history.push("/login");
            }, 5000);
          } else {
            showNotify(
              "danger",
              t("Common.error"),
              t("validation.invalidCaptcha")
            );
          }
        });
      }
    }
  };
  const showNotify = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: t(header),
        message: t(message),
      },
    }));
  };
  const getRandom = () => {
    captchvalue =
      BASE + "/backoffice/captcha/load_captcha/admin/" + Math.random();
    setState((prevState) => ({
      ...prevState,
      captchvalue:
        BASE + "/backoffice/captcha/load_captcha/admin/" + Math.random(),
    }));
  };

  useEffect(() => {
    getRandom();
  }, [captchvalue]);

  return (
    <>
      <div className="mb-2">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Login.forgotPassword")}
          </title>
        </Helmet>

        {show && (
          <Alert dismissible variant="warning" onClose={() => setShow(false)}>
            <span style={{ color: "green" }}>
              This feature is not available in DEMO
            </span>
          </Alert>
        )}
        <form id="forgot-form">
          <Forms
            type="text"
            name="userName"
            change={onchange}
            error={state.FormError.userName}
            from={"forgot"}
            blurhandler={blurhandler}
          />
          <Forms
            type="email"
            name="Email"
            change={onchange}
            error={state.FormError.Email}
            from={"forgot"}
            blurhandler={blurhandler}
          />
          <div style={{ textAlign: "center" }}>
            <img
              src={state.captchvalue}
              style={{ verticalAlign: "middle" }}
              alt="No Image"
              width={100}
              height={100}
            />

            <a
              style={{
                verticalAlign: "middle",
                fontSize: "15px",
                float: "right",
                cursor: "pointer",
              }}
              onClick={getRandom}
            >
              {" "}
              {t("Common.changeCaptcha")}{" "}
            </a>
          </div>

          <Forms
            type="text"
            name="captcha"
            change={onchange}
            error={state.FormError.captcha}
            from={"forgot"}
            blurhandler={blurhandler}
          />
          <ForgotButton submit={onSubmit} />
        </form>
      </div>
    </>
  );
}
export default withTranslation()(ForgotPassword);

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import style from './invoice.module.scss';
import { CurrencyDecimalFormat } from '../../helper';
import DataTable from 'react-data-table-component';
function ReportTable(props) {
    console.log('report Table,',props)
    const Currency = useSelector((state) => state.curr)
    const { t } = useTranslation();
    const columns = [
        {
            name: t('Common.category'),
            selector: row => row.category
        },
        {
            name: t('Common.totalAmount'),
            selector: row => row.amount,
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.amount * Currency.value, Currency.precision)}</span>

        },
        {
            name: t('Common.tax'),
            selector: row => row.tax,
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.tax * Currency.value, Currency.precision)}</span>

        },
        {
            name: t('Common.serviceChange'),
            selector: row => row.serviceCharge,
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.serviceCharge * Currency.value, Currency.precision)}</span>

        },
        {
            name: t('Common.amountPayable'),
            selector: row => row.amountPayable,
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.amountPayable * Currency.value, Currency.precision)}</span>

        },
        {
            name: t('Common.transaction_date'),
            selector: row => row.transaction_date
        }
    ]
    const handlePerRowsChange = (perPage, inPage) => {
        props.paginationchange(
            inPage,
            perPage
        )
    };
    const handlePageChange = inPage => {
        props.paginationchange(
            inPage,
            props.perPage
        )
    };
    return (
        <div>
            <DataTable
                columns={columns}
                data={props.data}
                highlightOnHover
                progressPending={props.pending}
                progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                responsive
                paginationTotalRows={props.totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noDataComponent={t('Common.noDataRecordsToDisplay')}
            // onSort={handleOnSort}
            />
        </div>
    )
}

export default ReportTable
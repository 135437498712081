import React from "react";
import { useTranslation } from "react-i18next";
import { FormInputControl, DropDownBs, DatePickers } from "../common";
import style from "./profile.module.scss";
import { Form } from 'react-bootstrap'
function FormsControl(props) {
  const { t } = useTranslation();
  const Components = (type) => {
    if (type === "text" && props.data.code == "mobile") {
      return (
        <div className="row">
          <div className={style.mobile_num_fld_view}>
            <label className={style.mob_fld_lbl} htmlFor="">
              {t("profile." + props.data.code)}
            </label>
            <div className={style.mobile_num_fld}>
              <Form.Group className={`${style.formGroup}`}>
                <Form.Control
                  className={`${style.formsInputField}`}
                  type="text"
                  disabled
                  value={
                    props.data.country_code
                      ? "+" + props.data.country_code
                      : "+00"
                  }
                  readOnly
                />
              </Form.Group>
              <FormInputControl
                required={props.data.required}
                inputProps={{
                  id: props.data.code,
                  name: props.data.code,
                  type: "text",
                  value: props.data.value ?? "",
                  disabled: props.status,
                  onChange: (e) => props.change(e, props.data.required),
                }}
                error={{
                  error: props.data.error,
                  field: props.data.errorField,
                }}
              />
            </div>
          </div>
        </div>
        // <div className="row">
        //   <div className={style.mobile_num_fld_view}>
        //     <label className={style.mob_fld_lbl} htmlFor="">
        //       {t("profile." + props.data.code)}
        //     </label>
        //     <div className={style.mobile_num_fld}>
        //       <Form.Group className={`${style.formGroup}`}>
        //         <Form.Control
        //           className={`${style.formsInputField}`}
        //           type="text"
        //           disabled
        //           value={
        //             props.data.country_code
        //               ? "+" + props.data.country_code
        //               : "+00"
        //           }
        //           readOnly
        //         />
        //         <FormInputControl

        //           required={props.data.required}
        //           inputProps={{
        //             id: props.data.code,
        //             name: props.data.code,
        //             type: "text",
        //             value: props.data.value ?? "",
        //             disabled: props.status,
        //             onChange: (e) => props.change(e, props.data.required),
        //           }}
        //           error={{
        //             error: props.data.error,
        //             field: props.data.errorField,
        //           }}
        //         />
        //       </Form.Group>

        //     </div>
        //   </div>
        // </div>
      );
    }
    if (type === "text") {
      return (
        <FormInputControl
          label={t("profile." + props.data.code)}
          required={props.data.required}
          inputProps={{
            id: props.data.code,
            name: props.data.code,
            type: "text",
            value: props.data.value ?? "",
            disabled: props.status,
            onChange: (e) => props.change(e, props.data.required),
          }}
          error={{
            error: props.data.error,
            field: props.data.errorField,
          }}
        />
      );
    } else if (type === "select") {
      return (
        <DropDownBs
          label={t("profile." + props.data.code)}
          required={props.data.required}
          options={{
            data: props.data.options,
            value: "value",
            label: "code",
          }}
          DropdownProps={{
            disabled: props.status,
            onChange: (e) => {
              props.dropdownChange(e.target.value, props.data);
            },
            value: props.data.value ?? "",
          }}
        />
      );
    } else if (type === "date") {
      return (
        <DatePickers
          disabled={props.status}
          start={props.data.value}
          datechange={props.dateChange}
          id={props.data.code}
          label={t("profile." + props.data.code)}
          error={{
            error: props.data.error,
            field: props.data.errorField,
          }}
        />
      );
    }
  };
  return Components(props.data.type);
}

export default FormsControl;


// //Local connect
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// // const BASE_URL = "http://199.192.29.234:5000/api/";
// const BASE_URL = "http://192.168.9.9:5002/api/";
// const IMG_URL = "http://199.192.29.234:5000/uploads/images/";
// const BASE = "http://199.192.29.234:5000";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '1066_'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// };




//Live connect
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "http://199.192.29.234:5000/api/";
const BASE_URL = "https://admin.direct-food.org/api/";
// const BASE_URL = "http://192.168.9.9:5002/api/";
const IMG_URL = "https://admin.direct-food.org/uploads/images/";
const BASE = "https://admin.direct-food.org";
const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY = '1066_'
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
};




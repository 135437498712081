import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
//components
import AlertMessage from "../../components/common/AlertMessage";
import PageTitle from "../../components/common/pageTitle";
import { Content, Tiles } from "../../components/payin";
//style
import style from "../../components/payin/payin.module.scss";
//service
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import PayinService from "../../../service/payin";
import companyName from "../../../store/mobxStore/companyName";
import Spinner from "../../shared/Spinner";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
function Payin(props) {
  const { t } = props;
  const Currency = useSelector((state) => state.curr);
  let history = useHistory();
  const [state, setState] = useState({
    requestModal: false,
    tilesData: [],
    notifyOn: false,
    requestBtn: false,
    loading: true,
    productrequestModal: false,
    gadgetrequestModal: false,
    button_show: false,
    notify: {
      message: "",
      type: "",
      header: "",
    },
    DataTables: {
      key: "paid",
      walletType: "0",
      pending: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inpage: 1,
        selectedRows: [],
        closePopUp: false,
      },
      approved: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inpage: 1,
      },
      paid: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inpage: 1,
      },
      rejected: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inpage: 1,
      },
    },
  });

  const [loader, setLoader] = useState({
    search: false,
    reset: false,
    excel: false,
    csv: false,
  });

  const service = new PayinService();

  useEffect(() => {
    loadData();
  }, []);

  const Translation = (data) =>
    Object.keys(data).map((item) => {
      return t("Common." + data[item]);
    });

  const addCurrency = (data) => {
    let newData = data.map((item) => {
      if (state.DataTables.key == "pending") {
        return {
          ...item,
          ewallet_balance: `${Currency.currentCurr}${(
            parseFloat(item.ewallet_balance) * Currency.value
          ).toFixed(Currency.precision)}`,
          payout_amount: `${Currency.currentCurr}${(
            parseFloat(item.payout_amount) * Currency.value
          ).toFixed(Currency.precision)}`,
        };
      }
      if (
        state.DataTables.key == "paid" ||
        state.DataTables.key == "approved" ||
        state.DataTables.key == "rejected"
      ) {
        return {
          ...item,
          amount: `${Currency.currentCurr}${(
            parseFloat(item.amount) * Currency.value
          ).toFixed(Currency.precision)}`,
        };
      } else return item;
    });
    return newData;
  };

  const ExportToExcel = (apiData, fileName, type) => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let fileExtension = ".xlsx";

    if (type === "excel") {
      // const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(addCurrency(apiData));
      console.log(ws);
      if (apiData.length > 0) {
        XLSX.utils.sheet_add_aoa(ws, [Translation(Object.keys(apiData[0]))]);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        setLoader({
          search: false,
          reset: false,
          excel: false,
          csv: false,
        });
      } else {
        setLoader({
          search: false,
          reset: false,
          excel: false,
          csv: false,
        });
      }
      // };
    } else if (type === "csv") {
      fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(addCurrency(apiData));
      if (apiData.length > 0) {
        XLSX.utils.sheet_add_aoa(ws, [Translation(Object.keys(apiData[0]))]);
        const csvOutput = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csvOutput], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        setLoader({
          search: false,
          reset: false,
          excel: false,
          csv: false,
        });
      } else {
        setLoader({
          search: false,
          reset: false,
          excel: false,
          csv: false,
        });
      }
    } else if (type === "print") {
      //  let printView = <UserEarningsPrintView
      //      data={apiData}
      // />
    }
  };

  const removeData = (data) => {
    let newdata = data.filter((item, key) => {
      delete item["request_id"];

      return t(item);
    });

    return newdata;
  };
  // Export Data
  const exportData = (type) => {
    if (type === "excel") {
      setLoader({
        search: false,
        reset: false,
        excel: true,
        csv: false,
      });
    } else {
      setLoader({
        search: false,
        reset: false,
        excel: false,
        csv: true,
      });
    }
    if (state.DataTables.key === "pending") {
      service.PendingTable(0, 100, "", "").then((res) => {
        if (type === "excel") {
          ExportToExcel(
            removeData(res.data.table_data),
            "Payout Pending",
            type
          );
        }
        if (type === "csv") {
          ExportToExcel(
            removeData(res.data.table_data),
            "Payout Pending",
            type
          );
        }
      });
    } else if (state.DataTables.key === "approved") {
      service.ApprovedTable(0, 100, "", "").then((res) => {
        if (type === "excel") {
          ExportToExcel(res.data.table_data, "Payout Approved", type);
        }
        if (type === "csv") {
          ExportToExcel(res.data.table_data, "Payout Approved", type);
        }
      });
    } else if (state.DataTables.key === "paid") {
      service.PaidTable(0, 100, "", "").then((res) => {
        if (type === "excel") {
          ExportToExcel(res.data.table_data, "Payout Approved Paid", type);
        }
        if (type === "csv") {
          ExportToExcel(res.data.table_data, "Payout Approved Paid", type);
        }
      });
    } else if (state.DataTables.key === "rejected") {
      service.RejectedTable(0, 100, "", "").then((res) => {
        if (type === "excel") {
          ExportToExcel(res.data.table_data, "Payout Rejected", type);
        }
        if (type === "csv") {
          ExportToExcel(res.data.table_data, "Payout Rejected", type);
        }
      });
    }
  };

  //initial loading
  const loadData = () => {
    service.PayinTiles().then((res) => {
      console.log("loadData", res);
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          requestBtn: res.data.button_show,
          tilesData: res.data.payout_tile,
          button_show: res.data.specialAccess,
          loading: false,
        }));
      }
    });
    // getPaidTable();
    //  getPendingTable()
    getApprovedTable();
  };
  //get the pending table
  const getPendingTable = (
    inPage = state.DataTables.pending.inpage,
    perPage = state.DataTables.pending.perPage,
    order = "",
    dierection = "",
    walletType = state.DataTables.walletType
  ) => {
    let start = (inPage - 1) * perPage;
    service
      .PendingTable(start, perPage, order, dierection, walletType)
      .then((res) => {
        if (res.status) {
          setLoader({
            search: false,
            reset: false,
          });

          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              pending: {
                ...prevState.DataTables.pending,
                totalRows: res.data.count,
                data: res.data.table_data,
                pending: false,
              },
            },
          }));
        }
      });
  };

  //pagination change
  const changePendingTable = (
    inPage,
    perPage,
    order = "",
    dierection = "",
    walletType = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        pending: {
          ...prevState.DataTables.pending,
          perPage: perPage,
          inPage: inPage,
          pending: true,
        },
      },
    }));
    getPendingTable(inPage, perPage, order, dierection, walletType);
  };

  //close the selected popup
  const cancelPopup = (row, isCancel) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        pending: {
          ...prevState.DataTables.pending,
          selectedRows: row,
          closePopUp: isCancel,
        },
      },
    }));
  };

  //cancel submit
  const CancelSubmit = () => {
    // let cancelationData = new FormData();
    let payouts = [];
    state.DataTables.pending.selectedRows.forEach((element) => {
      payouts.push(element);
    });
    service.CancelRequest(payouts).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          notifyOn: true,
          notify: {
            ...prevState.notify,
            message: res.data.message,
            type: "success",
            header: props.t("Common.Success"),
          },
          DataTables: {
            ...prevState.DataTables,
            pending: {
              ...prevState.DataTables.pending,
              selectedRows: [],
              closePopUp: false,
            },
          },
        }));

        getPendingTable();
        loadData();
      } else {
        if (res.error.code === 1019) {
        }
      }
    });
  };

  const getApprovedTable = (
    inPage = state.DataTables.approved.inpage,
    perPage = state.DataTables.approved.perPage,
    order = "",
    dierection = "",
    walletType = state.DataTables.walletType
  ) => {
    let start = (inPage - 1) * perPage;
    service
      .ApprovedTable(start, perPage, order, dierection, walletType)
      .then((res) => {
        if (res.status) {
          setLoader({
            search: false,
            reset: false,
          });

          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              approved: {
                ...prevState.DataTables.approved,
                totalRows: res.data.count,
                data: res.data.table_data,
                pending: false,
              },
            },
          }));
        }
      });
  };
  //change Approved Table paignation change
  const changeApprovedTable = (
    inPage,
    perPage,
    order = "",
    dierection = "",
    walletType = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        approved: {
          ...prevState.DataTables.approved,
          perPage: perPage,
          inPage: inPage,
          pending: true,
        },
      },
    }));
    getApprovedTable(inPage, perPage, order, dierection, walletType);
  };

  //get Paid table datat
  const getPaidTable = (
    inPage = state.DataTables.paid.inpage,
    perPage = state.DataTables.paid.perPage,
    order = "",
    dierection = "",
    walletType = state.DataTables.walletType
  ) => {
    let start = (inPage - 1) * perPage;
    service
      .PaidTable(start, perPage, order, dierection, walletType)
      .then((res) => {
        if (res.status) {
          setLoader({
            search: false,
            reset: false,
          });

          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              paid: {
                ...prevState.DataTables.paid,
                totalRows: res.data.count,
                data: res.data.table_data,
                pending: false,
              },
            },
          }));
        }
      });
  };

  //change paid data table
  const changePaidTable = (
    inPage,
    perPage,
    order = "",
    dierection = "",
    walletType = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        paid: {
          ...prevState.DataTables.paid,
          perPage: perPage,
          inPage: inPage,
          pending: true,
        },
      },
    }));
    getPaidTable(inPage, perPage, order, dierection, walletType);
  };

  //get the rejected table data
  const getRejectedTable = (
    inPage = state.DataTables.rejected.inpage,
    perPage = state.DataTables.rejected.perPage,
    order = "",
    dierection = "",
    walletType = state.DataTables.walletType
  ) => {
    let start = (inPage - 1) * perPage;
    service
      .RejectedTable(start, perPage, order, dierection, walletType)
      .then((res) => {
        if (res.status) {
          setLoader({
            search: false,
            reset: false,
          });

          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              rejected: {
                ...prevState.DataTables.rejected,
                totalRows: res.data.count,
                data: res.data.table_data,
                pending: false,
              },
            },
          }));
        }
      });
  };

  //change rejecte
  const changeRejectedTable = (
    inPage,
    perPage,
    order = "",
    dierection = "",
    walletType = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        rejected: {
          ...prevState.DataTables.rejected,
          perPage: perPage,
          inPage: inPage,
          pending: true,
        },
      },
    }));
    getRejectedTable(inPage, perPage, order, dierection, walletType);
  };

  //tab change
  const changePageDrop = (key, type) => {
    console.log("changePageDrop", key);
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,

        key: key,
        walletType: type,
      },
    }));

    if (
      key === "pending" ||
      type === "cashwallet" ||
      type === "productwallet" ||
      type === "gadgetwallet"
    ) {
      getPendingTable("", "", "", "", type);
    } else if (
      key === "approved" ||
      type === "cashwallet" ||
      type === "productwallet" ||
      type === "gadgetwallet"
    ) {
      getApprovedTable("", "", "", "", type);
    } else if (
      key === "paid" ||
      type === "cashwallet" ||
      type === "productwallet" ||
      type === "gadgetwallet"
    ) {
      getPaidTable("", "", "", "", type);
    } else if (
      key === "rejected" ||
      type === "cashwallet" ||
      type === "productwallet" ||
      type === "gadgetwallet"
    ) {
      getRejectedTable("", "", "", "", type);
    }
  };

  //modal open
  const requestModalOpen = () => {
    history.push("/load");
  };

  //close Modal
  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      requestModal: false,
      productrequestModal: false,
      gadgetrequestModal: false,
    }));
  };

  //notifydismissed
  const notifyDissmissed = () => {
    setState((prevState) => ({
      ...prevState,
      notifyOn: false,
    }));
  };

  const productrequestModalOpen = () => {
    setState((prev) => ({
      ...prev,
      productrequestModal: true,
    }));
  };
  const gadgetrequestModalOpen = () => {
    setState((prev) => ({
      ...prev,
      gadgetrequestModal: true,
    }));
  };
  const buttonshowModal = () => {
    return state.button_show === true ? true : false;
  };
  const buttonGroups = [
    {
      name: "payinRequest",
      click: requestModalOpen,
    },
  ];

  const paginationHandler = {
    approved: changeApprovedTable,
    pending: changePendingTable,

    // paid: changePaidTable,
    rejected: changeRejectedTable,
    cancel: cancelPopup,
    submit: CancelSubmit,
  };

  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.Payin")}
        </title>
      </Helmet>
      <PageTitle
        title="Payin"
        buttonOn={true}
        payin={true}
        group={buttonGroups}
      />
      {state.loading ? (
        <Spinner />
      ) : (
        <div className={style.MainContainer}>
          <Tiles tilesData={state.tilesData} />
          <Content
            tableData={state.DataTables}
            changeHandler={changePageDrop}
            paginationHandler={paginationHandler}
            exportData={exportData}
            loader={loader}
          />
          {state.notifyOn && (
            <AlertMessage
              message={state.notify.message}
              dismiss={notifyDissmissed}
              type={state.notify.type}
              header={state.notify.header}
              show={state.notifyOn}
            />
          )}
        </div>
      )}
    </div>
  );
}
export default withTranslation()(Payin);

import React from "react";
import {Redirect} from 'react-router-dom';

import Dashboard from '../view/dashboard/home';
import Ewallet from '../view/ewallet/Ewallet';
import Payout  from  '../view/payout/Payout';
import Profile  from '../view/Profile/ViewProfile';
import Epin from '../view/epin/Epin';
import Register from '../view/auth/register/Register';
import Upgrade from '../view/package/Upgrade';
import Subscription from '../view/package/Subscription';
import Preview from '../view/auth/register/Preview';
import Kyc from '../view/Profile/Kyc';
import Logout from '../view/auth/login/Logout';
import GenealogyTree from '../view/tree/GenealogyTree';
import MailBox from '../view/mailbox/MailBox';
import ReferralMember from '../view/tree/ReferralMember';
import DownLine from '../view/tree/DownLineMember';
import TreeView from '../view/tree/TreeView';
// import NotFount from '../components/404/NotFount';
import DownLoadMaterials from '../view/tools/DownloadMaterial';
import Leads from '../view/tools/Leads';
import ReplicaConfiguration from '../view/tools/ReplicaConfiguration';
import News from '../view/tools/News';
import ViewNews from '../view/tools/ViewNews';
import Faqs from '../view/tools/Faqs';
import PromotionalTools from '../view/tools/PromotionalTools';
import ShoppingCart from '../view/shopping_cart/ShoppingCart'
import PackageDetails from '../view/shopping_cart/PackageDetails'
// import Tree from '../view/testTree/components/Tree';
import SponsorTree from '../view/tree/sponsorTree';
import CheckOut from '../view/shopping_cart/CheckOut';
import ViewInvoice  from '../view/shopping_cart/ViewInvoice';
import RepurchaseReport from '../view/shopping_cart/RepurchaseReport'
import Support from '../view/support/Support';
import TicketTimeLine from '../view/support/TicketTimeLine';
import TicketDetails from '../view/support/TicketDetails';
import OrderHistory from '../view/order/orderHistory';
import BoardView from '../view/tree/BoardView';
import BoardViewTree from '../view/tree/BoardViewTree';
import MissedDonation from '../view/donation/MissedDonation';
import RecieveDonation from '../view/donation/RecieveDonation';
import SendDonation from '../view/donation/SendDonation';
import StepView from '../view/tree/stepView';
import DonationView from '../view/donation/DonationView';
import Crm from '../view/crm';
import  CrmViewLead  from '../view/crm/viewLead';
import AddLead from '../view/crm/addLead'
import SetupParty from '../view/party/SetupParty';
import EditInvite from '../view/tools/EditInvite';
import CrmTimeLine from "../view/crm/CrmTimeLine";
import Graph from "../view/crm/graph";
import  Login from '../view/auth/login/Login';
import ForgotPassword from "../view/auth/login/ForgotPassword";
import DthreeView from "../view/tree/DthreeView";
import Replica from "../view/Replica/home";
import privacy from '../view/Replica/privacy';
import terms from '../view/Replica/terms';
import replicaRegister from '../view/auth/replicaregister/Register';
import Replicapreview from "../view/auth/replicaregister/Replicapreview";
import Lcp from "../view/lcp/home";
import Guest from "../view/party/Guest";
import Host from "../view/party/Host";
import addGuest from "../view/party/addGuest";
import addHost from "../view/party/addHost";
import store from  "../view/store/Home"
import Productwallet from "../view/ProductWallet/productwallet";
import GadgetWallet from "../view/GadgetWallet/GadgetWallet";
import CommissionReport from "../view/Commission Report/CommissionReport";
import Payin from "../view/payin/Payin";
import payinLoad from "../view/payin/payinLoad";
// const Payout    = lazy(() => import('../view/payout/Payout'));

const routes = [
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/dashboard" />
    },
    {
        path :'/dashboard',
        exact : true,
        component:Dashboard
    },
    {
        path :'/cashwallet',
        exact : true,
        component :Ewallet
    },
    {
        path:'/productwallet',
        exact:true,
        component:Productwallet
    },
    {
        path:'/gadgetwallet',
        exact:true,
        component:GadgetWallet
    },
    {
        path : '/payout',
        exact : true,
        component :Payout
    },
    {
        path : '/report',
        exact : true,
        component :CommissionReport
    },
    {
        path : '/profile',
        exact : true,
        component :Profile
    },
    {
        path : '/epin',
        exact : true,
        component :Epin
    },
    {
        path : '/register',
        exact : true,
        component :Register
    },
    {
        path : '/register/:userId/:leg/:reg_from_tree',
        exact : true,
        component :Register
    },
    {
        path : '/upgrade_package',
        exact : true,
        component :Upgrade
    },
    {
        path : '/upgrade_validity',
        exact : true,
        component :Subscription
    },
    {
        path : '/preview/:id',
        exact : true,
        component :Preview
    },
    {
        path : '/kyc_upload',
        exact : true,
        component :Kyc
    },
    {
        path :  "/logout",
        exact : true,
        component : Logout
    },
    {
        path : "/genealogyTree",
        exact  :true,
        component : GenealogyTree
    },
    {
        path : "/genealogyTree/:userName",
        exact  :true,
        component : GenealogyTree
    },
    {
        path : "/mailbox",
        exact  :true,
        component : MailBox
    },
    {
        path : "/mailbox/:action",
        exact  :true,
        component : MailBox
    },
    {
        path : "/mailbox/:action/:id",
        exact  :true,
        component : MailBox
    },
    {
        path : "/mailbox/:action/:id/:type/:thread",
        exact  :true,
        component : MailBox
    },
    {
        path : "/mailbox/:action/:id/:type",
        exact  :true,
        component : MailBox
    },
    {
        path  : "/referralMembers",
        exact : true,
        component : ReferralMember
    },
    {
        path : '/downlineMembers',
        exact : true,
        component : DownLine
    },
    {
        path : "/treeView",
        exact : true,
        component : TreeView
    },
    {
        path : "/sponsorTree",
        exact : true,
        component : SponsorTree
    },
    {
        path : "/sponsorTree/:userName",
        exact : true,
        component : SponsorTree
    },
    {
        path : '/downloadDocument',
        exact : true,
        component : DownLoadMaterials
    },
    {
        path : '/leads',
        exact : true,
        component : Leads
    },
    {
        path : '/replica_configuration',
        exact : true,
        component : ReplicaConfiguration
    },
    {
        path : '/news',
        exact : true,
        component : News
    },
    {
        path : '/news/:id',
        exact : true,
        component : ViewNews
    },
    {
        path : '/faq',
        exact : true,
        component : Faqs
    },
    {
        path : '/promotion_status',
        exact : true,
        component : PromotionalTools
    },
    {
        path: "/shopping",
        exact: true,
        component: ShoppingCart
    },
    {
        path: "/package_details/:id",
        exact: true,
        component: PackageDetails
    },
    {
        path: "/checkOut",
        exact: true,
        component: CheckOut
    },
    {
        path: "/repurchaseInvoice/:id",
        exact: true,
        component: ViewInvoice
    },
    {
        path: "/repurchaseReport",
        exact: true,
        component: RepurchaseReport
    },
    {
        path: "/support",
        exact: true,
        component: Support
    },

    {
        path: "/support/ticket-time-line/:id",
        exact: true,
        component: TicketTimeLine
    },

    {
        path: "/support/ticket-details/:id",
        exact: true,
        component: TicketDetails
    },
    {
        path : '/Orderhistory',
        exact : true,
        component : OrderHistory
    },
    {
        path : '/BoardView',
        exact : true,
        component : BoardView
    },
    {
        path : '/BoardView/:id',
        exact : true,
        component : BoardView
    },
    {
        path : '/BoardView/:id/:userId',
        exact : true,
        component : BoardViewTree
    },
    {
        path : '/missedDonation',
        exact : true,
        component : MissedDonation
    },
    {
        path : '/recievedDonation',
        exact : true,
        component : RecieveDonation
    },
    {
        path : '/sendDonation',
        exact : true,
        component : SendDonation
    },
    {
        path : '/stepView',
        exact : true,
        component : StepView
    },
    {
        path : '/DonationView',
        exact : true,
        component : DonationView
    },

    {
        path: "/crmDashboard",
        exact: true,
        component: Crm
    },
    {
        path:"/viewLead",
        exact:true,
        component:CrmViewLead
    },
    {
        path:"/viewLead/:status",
        exact:true,
        component:CrmViewLead
    },
    {
        path:"/addLead",
        exact:true,
        component:AddLead
    },
    {
        path: "/graph",
        exact: true,
        component:Graph
    },
    {
        path: "/crm/timeline/:id",
        exact: true,
        component:CrmTimeLine
    },
    {
        path: "/createParty",
        exact: true,
        component:SetupParty
    },
    {
        path  : '/editInvite/:id',
        exact : true,
        component : EditInvite
    },
   {
    
        path  : '/guest_manager',
        exact : true,
        component : Guest
    },
   {
    
    path  : '/guest_manager',
    exact : true,
    component : Guest
   },
   {
    
    path  : '/host_manager',
    exact : true,
    component : Host
   },
   {
    
    path  : '/add_host/:id',
    exact : true,
    component : addHost
   },
    {
    
    path  : '/add_host',
    exact : true,
    component : addHost
   },
   {
    path:"/add_guest/:id",
    exact:true,
    component:addGuest
},
{
    path:"/add_guest",
    exact:true,
    component:addGuest
},
{
    path:"/store",
    exact:true,
    component:store
},
{
    path:"/payin",
    exact:true,
    component:Payin
},
{
    path:"/load",
    exact:true,
    component:payinLoad
},


   


];

const publicRoute = [
   
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/login" />
    },
    {
        path:"/login/:adminusername/:username",
        exact:true,
        component:Login
    },

    {
        path:'/login',
        exact:true,
        component:Login
    },

    {
        path : '/register',
        exact : true,
        component :Register
    },
    {
        path : '/forgotPassword',
        exact : true,
        component :ForgotPassword
    },


]

const replicaRoute = [
    {
        path:"/referral/:user_name",
        exact:true,
        component:Replica
    },
    {
        path:"/privacy-policy/:user_name",
        exact:true,
        component:privacy
    },
    {
        path:"/terms-conditions/:user_name",
        exact:true,
        component:terms
    },
    {
        path:"/referral_register/:sponser_id",
        exact:true,
        component:replicaRegister
    },
    {
        path:"/referral_preview/:id",
        exact:true,
        component:Replicapreview
    },
    {
        path:"/lcp/:user_name/:admin",
        exact:true,
        component:Lcp
    },
    {
        path  : '/store/:type',
        exact : true,
        component : DthreeView
    },
    

  

]

export  {routes,publicRoute,replicaRoute};
import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Spinner from "../../shared/Spinner";
import { IMG_URL } from "../../../configuration/apiconfig";
import { SetupContent } from "../../components/party";
import PartySetupService from "../../../service/party/setUp";
import companyName from "../../../store/mobxStore/companyName";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import { withRouter } from "react-router-dom";
import { AlertMessage } from "../../components/common";

function SetupParty({ t }) {
  let service = new PartySetupService();
  let history = useHistory();

  const [state, setState] = useState({
    loading: false,
    notify: {
      show: false,
      message: "",
      type: "",
      header: "",
    },
    data: {
      host: [],
    },
    partyContent: {
      name: "",
      image: IMG_URL + "uploads/images/party_image/party_image.png",
      uploadFile: "",
      host: "old",
      oldHost: "",
      country: [],
      states: [],
      from: "",
      startTime: "00:00",
      to: "",
      endTime: "00:00",
      address: "host_address",
      FormError: {
        name: "",
        from: "",
        startTime: "",
        to: "",
        endTime: "",
        uploadFile: "",
      },
      newHost: {
        firstName: "",
        lastName: "",
        address: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        email: "",
        FormError: {
          firstName: "",
          lastName: "",
          address: "",
          phone: "",
          country: "",
          state: "",
          city: "",
          zip: "",
          email: "",
        },
      },
      newAddress: {
        firstName: "",
        lastName: "",
        address: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        email: "",
        FormError: {
          firstName: "",
          lastName: "",
          address: "",
          phone: "",
          country: "",
          state: "",
          city: "",
          zip: "",
          email: "",
        },
      },
    },
  });

  const FieldSet = [
    {
      code: "firstName",
      minLength: 3,
      maxLength: 25,
      regex: "^[a-zA-Z ]*$",
    },
    {
      code: "lastName",
      minLength: 3,
      maxLength: 32,
      regex: "^[a-zA-Z ]*$",
    },
    {
      code: "zipCode",
      minLength: 3,
      maxLength: 10,
      regex: "^[0-9-+]+$",
    },
    {
      code: "city",
      minLength: 3,
      maxLength: 32,
      regex: "^[a-zA-Z ]*$",
    },
    {
      code: "phone",
      minLength: 5,
      maxLength: 15,
      regex: "^[0-9]{15}$",
    },
    {
      code: "email",
      minLength: "",
      maxLength: "",
      regex: "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$",
    },
  ];

  useEffect(() => {
    service.getHost().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          data: {
            ...state.data,
            host: res.data.result,
          },
          partyContent: {
            ...state.partyContent,
            oldHost: res.data.result[0].id,
          },
        }));
      } else {
        if (res.error.code === 1002) {
          history.push("/logout");
        }
      }
    });
    getCountry();
  }, []);

  const getCountry = () => {
    service.getCountry().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          partyContent: {
            ...prevState.partyContent,
            country: res.data.country,
          },
        }));
      } else {
        history.push("/logout");
      }
    });
  };

  const changeHandler = (e, isNew = false, key = "") => {
    const { name, value } = e.target;
    if (name === "country") {
      service.countryChange(value).then((res) => {
        if (res.status) {
          setState((prevState) => ({
            ...prevState,
            partyContent: {
              ...prevState.partyContent,
              states: res.data,
            },
          }));
        } else {
          history.push("/logout");
        }
      });
    }
    if (key == "newAddress") {
      setState((prevState) => ({
        ...prevState,
        partyContent: {
          ...state.partyContent,
          newAddress: {
            ...state.partyContent.newAddress,
            [name]: value,
            FormError:{
              [name]:""
            }
          },
        },
      }));
    } else if (key == "newHost") {
      setState((prevState) => ({
        ...prevState,
        partyContent: {
          ...state.partyContent,
          newHost: {
            ...state.partyContent.newHost,
            [name]: value,
            FormError:{
              [name]:""
            }
          },
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        partyContent: {
          ...state.partyContent,
          [name]: value,
          FormError: {
            [name]: "",
          },
        },
      }));
    }
    // if (isNew) {
    //   setState({
    //     ...state,
    //     partyContent: {
    //       ...state.partyContent,
    //       [key]: {
    //         ...state.partyContent.newHost,
    //         [name]: value,
    //         FormError:{
    //           [name]:""
    //         }
    //       },
    //     },
    //   });
    // } else {
    //   setState((prevState) => ({
    //     ...prevState,
    //     partyContent: {
    //       ...state.partyContent,
    //       [name]: value,
    //       FormError:{
    //         [name]:""
    //       }
    //     },
    //   }));
    // }
  };

  const removeImage = () => {
    setState({
      ...state,
      partyContent: {
        ...state.partyContent,
        uploadFile: "",
        image: IMG_URL + "uploads/images/party_image/party_image.png",
      },
    });
  };
  const changeFile = (e) => {
    let reader = new FileReader();
    reader.onload = (event) => {
      setState((prevState) => ({
        ...prevState,
        partyContent: {
          ...state.partyContent,
          uploadFile: e.target.files[0],
          image: event.target.result,
        },
      }));
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const createParty = () => {
    const formData = new FormData();
    let isvalid = true;
    let content = state.partyContent;
    Object.keys(content).forEach((key, index) => {
      if (content[key] == "" && key!='states' && key!='uploadFile' ) {
        isvalid = false;
        content.FormError[key] = {
          error: "validation.required",
          field: {
            field: key,
          },
        };
      }
    });
    if (content["address"] === "new_address") {
      Object.keys(content.newAddress).forEach((key, index) => {
        if (content.newAddress[key] == "" && key!='states' && key!='uploadFile') {
          isvalid = false;
          content.newAddress.FormError[key] = {
            error: "validation.required",
            field: {
              field:
                key == "zip" ? "zipCode" : key == "phone" ? "phoneNumber" : key,
            },
          };
        } else {
          FieldSet.map((obj) => {
            if (content.newAddress[key] && key != "state") {
              console.log("objkey",obj.code,key)
              if (obj.code == key) {
                if (obj.minLength) {
                  if (content.newAddress[key].length < obj.minLength) {
                    isvalid = false;
                    content.newAddress.FormError[key] = {
                      error: "validation.min_length",
                      field: {
                        field:
                          key == "zip"
                            ? "zipCode"
                            : key == "phone"
                            ? "phoneNumber"
                            : key,
                        length: obj.minLength,
                      },
                    };
                  }
                }
                if (obj.maxLength) {
                  if (content.newAddress[key].length > obj.maxLength) {
                    isvalid = false;
                    content.newAddress.FormError[key] = {
                      error: "validation.max_length",
                      field: {
                        field:
                          key == "zip"
                            ? "zipCode"
                            : key == "phone"
                            ? "phoneNumber"
                            : key,
                        length: obj.maxLength,
                      },
                    };
                  }
                }
                if (obj.regex) {
                  let Rgex = new RegExp(obj.regex);
                  if (!Rgex.test(content.newAddress[key])) {
                    isvalid = false;
                    content.newAddress.FormError[key] = {
                      error: "validation.invalid",
                      field: {
                        field:
                          key == "zip"
                            ? "zipCode"
                            : key == "phone"
                            ? "phoneNumber"
                            : key,
                      },
                    };
                  }
                }
              }
            }
          });
        }
      });
    }
    if (content["host"] === "new") {
      Object.keys(content.newHost).forEach((key, index) => {
        console.log("key", key);
        if (content.newHost[key] == "") {
          isvalid = false;
          content.newHost.FormError[key] = {
            error: "validation.required",
            field: {
              field:
                key == "zip" ? "zipCode" : key == "phone" ? "phoneNumber" : key,
            },
          };
        } else {
          FieldSet.map((obj) => {
            if (content.newHost[key] && key != "state") {
              if (obj.code == key) {
                if (obj.minLength) {
                  if (content.newHost[key].length < obj.minLength) {
                    isvalid = false;
                    content.newHost.FormError[key] = {
                      error: "validation.min_length",
                      field: {
                        field:
                          key == "zip"
                            ? "zipCode"
                            : key == "phone"
                            ? "phoneNumber"
                            : key,
                        length: obj.minLength,
                      },
                    };
                  }
                }
                if (obj.maxLength) {
                  if (content.newHost[key].length > obj.maxLength) {
                    isvalid = false;
                    content.newHost.FormError[key] = {
                      error: "validation.max_length",
                      field: {
                        field:
                          key == "zip"
                            ? "zipCode"
                            : key == "phone"
                            ? "phoneNumber"
                            : key,
                        length: obj.maxLength,
                      },
                    };
                  }
                }
                if (obj.regex) {
                  let Rgex = new RegExp(obj.regex);
                  if (!Rgex.test(content.newHost[key])) {
                    isvalid = false;
                    content.newHost.FormError[key] = {
                      error: "validation.invalid",
                      field: {
                        field:
                          key == "zip"
                            ? "zipCode"
                            : key == "phone"
                            ? "phoneNumber"
                            : key,
                      },
                    };
                  }
                }
              }
            }
          });
        }
      });
    }
    formData.append("from", content.from);
    formData.append("startTime", content.startTime);
    formData.append("to", content.to);
    formData.append("endTime", content.endTime);
    formData.append("file", content.uploadFile);
    formData.append("host", content.host);
    formData.append("party_name", content.name);
    formData.append("oldHost", content.oldHost);
    if (content.host === "new") {
      formData.append("firstname", content.newHost.firstName);
      formData.append("second_name", content.newHost.lastName);
      formData.append("city", content.newHost.city);
      formData.append("phone", content.newHost.phone);
      formData.append("email", content.newHost.email);
      formData.append("country", content.newHost.country);
      formData.append("state", content.newHost.state);
      //formData.append('guest',content.newHost.state)
      formData.append("zip", content.newHost.zip);
    }
    if (content.address == "new_address") {
      formData.append("address", JSON.stringify(content.newAddress));
    } else {
      formData.append("address", content.address);
    }

    if (isvalid) {
      service.createParty(formData).then((res) => {
        if (res.status) {
          setState({
            ...state,
            loading: false,
            partyContent: {
              ...state.partyContent,
              name: "",
              image: IMG_URL + "uploads/images/party_image/party_image.png",
              uploadFile: "",
              host: "old",
              oldHost: "",
              from: "",
              startTime: "00:00",
              to: "",
              endTime: "00:00",
              address: "host_address",
              FormError: {
                name: "",
                from: "",
                startTime: "",
                to: "",
                endTime: "",
                uploadFile: "",
              },
              newHost: {
                firstName: "",
                lastName: "",
                address: "",
                phone: "",
                country: "",
                state: "",
                city: "",
                zip: "",
                email: "",
                FormError: {
                  firstName: "",
                  lastName: "",
                  address: "",
                  phone: "",
                  country: "",
                  state: "",
                  city: "",
                  zip: "",
                  email: "",
                },
              },
              newAddress: {
                firstName: "",
                lastName: "",
                address: "",
                phone: "",
                country: "",
                state: "",
                city: "",
                zip: "",
                email: "",
                FormError: {
                  firstName: "",
                  lastName: "",
                  address: "",
                  phone: "",
                  country: "",
                  state: "",
                  city: "",
                  zip: "",
                  email: "",
                },
              },
            },
            notify: {
              ...state.notify,
              show: true,
              message: "validation.partyAdded",
              type: "success",
              header: "Common.success",
            },
          });
        } else {
          setState({
            ...state,
            loading: false,
            notify: {
              ...state.notify,
              show: true,
              message: "validation.partyError",
              type: "error",
              header: "Common.error",
            },
          });
        }
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        partyContent: {
          ...state.partyContent,
          ...content,
        },
      }));
    }
  };
  if (state.loading) {
    return <Spinner />;
  }
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.setupParty")}
        </title>
      </Helmet>
      <PageTitle title="setupParty" buttonOn={false} />
      <SetupContent
        {...state}
        changeHandler={changeHandler}
        changeFile={changeFile}
        removeImage={removeImage}
        createParty={createParty}
      />
      {state.notify.show && (
        <AlertMessage
          type={state.notify.type}
          message={state.notify.message}
          show={state.notify.show}
          // dismiss={notifyDissmissed}
        />
      )}
    </div>
  );
}
export default withTranslation()(withRouter(SetupParty));

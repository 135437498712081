import React, { useRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
//style
import style from "./payin.module.scss";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import PayoutContentPrintView from "./PayoutContentPrintView";

//compoenents
import {
  DropDown,
  PendingTable,
  PaidTable,
  ApprovedTable,
  RejectedTable,
} from "./";

function Content(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const componentRef = useRef();
  return (
    <div className={style.summary}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_tabContent}`
            : `${style.tabContent}`
        }`}
      >
        <Tabs
          defaultActiveKey={`statement`}
          id="uncontrolled-tab-example"
          transition={false}
        >
          <Tab eventKey="statement" title={t("Common.payinSummary")}>
            <div className={style.dataTable}>
              <DropDown handler={props.changeHandler} loader={props.loader} />
              {props.tableData.key === "pending" && (
                <PendingTable
                  ContentData={props.tableData.pending}
                  paginationchange={props.paginationHandler.pending}
                  cancelPopupHandler={props.paginationHandler.cancel}
                  onSubmit={props.paginationHandler.submit}
                />
              )}
              {props.tableData.key === "approved" && (
                <ApprovedTable
                  ContentData={props.tableData.approved}
                  paginationchange={props.paginationHandler.approved}
                />
              )}
              {props.tableData.key === "paid" && (
                <ApprovedTable
                  ContentData={props.tableData.approved}
                  paginationchange={props.paginationHandler.approved}
                />
              )}
              {props.tableData.key === "rejected" && (
                <RejectedTable
                  ContentData={props.tableData.rejected}
                  paginationchange={props.paginationHandler.rejected}
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Content;

import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FormInputControl, AlertBs } from "../common";
import style from "./payment.module.scss";
import { useTranslation } from "react-i18next";
import Payments from "../../../service/common/PaymentApi";
import { useHistory } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import StripeCheckout from "react-stripe-checkout";
import { useSelector } from "react-redux";

function Stripe(props) {
  console.log("stripe",props.loader)
  const { t } = useTranslation();
  const history = useHistory();
  const service = new Payments();
  const [clientSecret, setClientSecret] = useState("");
  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;
  useEffect(() => {
    console.log(`props ${props.loading} ${JSON.stringify(props)}`)
    service.createPyament(props.product).then((res) => {
      setClientSecret(res.clientSecret);
    });
  }, []);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { Currency } = useSelector((state) => state.curr);
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  //   const onToken = (e, token) => {
  //     console.log(token);
  //     props.submit(e,token,'payment')
  //     // alert('Payment Succesful!');
  // };

  const onToken = useCallback((e, token) => {
    if (
      window.location.pathname.includes("/register") ||
      window.location.pathname.includes("/replica_register")
    ) {
      props.submit(e, token, "payment");
    } else {
      props.submit(e, token);
    }
  });

  return (
    <div className={`${style.MianContent}`}>
      <div>
        <pre
          className={`${
            currentTheme == "theme-dark"
              ? ` alert alert-danger`
              : `alert alert-info`
          }`}
        >
          {t("Common.clickFinish")}
        </pre>
        <Row>
          <Col md={12}>
            { props.stripeError && props.stripeError.error ? (
              <>
                <div className={style.Buttons}>
                  <AlertBs
                    variant="danger"
                    show="true"
                    message={props.stripeError.message}
                    close="true"
                  />
                  <div className={style.Buttons}>
                    {style.PrevButton && (
                      <Button
                        className={style.PrevButton}
                        onClick={props.prevButtonClick}
                      >
                        {t("profile.prev")}
                      </Button>
                    )}
                    <StripeCheckout
                      description={`Your total is $${props.amount}`}
                      amount={props.amount * 100}
                      panelLabel={t("Button.finish")}
                      token={(e, token) => onToken(e, token)}
                      stripeKey={publishableKey}
                      currency="usd"
                    />
                    {/* <Button className={style.NextButton} disabled={!state.isValid} type="submit">{t('Button.finish') }</Button> */}
                  </div>
                </div>
              </>
            ) : // <p style={{color:"red"}}>{props.stripeError.message}</p>
            props.loader ? (
              <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
        
            ) : (
              <div className={style.Buttons}>
                {style.PrevButton && (
                  <Button
                    className={style.PrevButton}
                    onClick={props.prevButtonClick}
                  >
                    {t("profile.prev")}
                  </Button>
                )}
                <StripeCheckout
                  description={`Your total is $${props.amount}`}
                  amount={props.amount * 100}
                  panelLabel={t("Button.finish")}
                  token={(e, token) => onToken(e, token)}
                  stripeKey={publishableKey}
                  currency="usd"
                />
                {/* <Button className={style.NextButton} disabled={!state.isValid} type="submit">{t('Button.finish') }</Button> */}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Stripe;

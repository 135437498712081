import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Image, Alert } from "react-bootstrap";
import style from "./payment.module.scss";
import Payments from "../../../service/common/PaymentApi";
import { useSelector } from "react-redux";
import PayinService from "../../../service/payin";

function MobileTransfer(props) {
  console.log("BankTransfer", props);
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new PayinService();
  const { t } = useTranslation();
  const [valid, setValid] = useState({
    checkAmVal: false,
  });

  const [state, setState] = useState({
    selectedFile: null,
    url: "",
    accoundDetails: "",
    enableFinish: false,
    showAlert: false,
    alertType: "",
    AlertMessage: "",
    imageUploaded: false,
    loader: false,
    theamount: 0
  });
  useEffect(() => {
    // const accoundService = new Payments();
    // accoundService.accoundDetails().then((res) => {
    //   if (res.status) {
    //     setState((prev) => ({
    //       ...prev,
    //       accoundDetails: res.data.account_info,
    //     }));
    //   }
    // });
  }, []);

  //file change event
  const onFileChange = (event) => {
    // Update the state
    if (event.target.files[0]) {
      setState((prev) => ({
        ...prev,
        selectedFile: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
      }));
    }
  };

  //changeHandler
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      // [name]: value,
      theamount: value,

      //Added after amount alert not getting reset
      enableFinish: false,
      showAlert: false,
    }));

    validAmount();
  };

  const validAmount = () => {
    var regexPattern = new RegExp(/^[0-9-+]+$/);
    if (!regexPattern.test(state.theamount)) {
      setState((prev) => ({
        ...prev,
        enableFinish: false,
        showAlert: true,
        alertType: "danger",
        AlertMessage: t("Common.entnum"),
      }));

      setValid((prev) => ({
        ...prev,
        checkAmVal: false,
      }));
    } else if (state.theamount.length === 0) {
      setValid((prev) => ({
        ...prev,
        checkAmVal: false,
      }));
    } else {
      setValid((prev) => ({
        ...prev,
        checkAmVal: true,
      }));
    }

    // uploadImgaeForEwalletLoad()
  };
  //upload image
  const uploadImgae = () => {
    const formData = new FormData();
    formData.append("file", state.selectedFile);
    formData.append("user_name", props.userName ?? "");
    formData.append("type", props.type ?? "subscription_renewal");
    console.log(`window.location.pathname ${window.location.pathname}`);
    if (window.location.pathname.includes("/load")) {
      service.upload_recipt(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            enableFinish: true,
            showAlert: true,
            alertType: "success",
            AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
            imageUploaded: true,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            enableFinish: false,
            showAlert: true,
            alertType: "danger",
            AlertMessage: t("Common.error_file_upload"),
          }));
        }
      });
    }
  };

  //remove image
  const removeImage = () => {
    setState((prev) => ({
      ...prev,
      selectedFile: "",
      url: "",
      enableFinish: false,
      showAlert: false,
    }));
    if (state.imageUploaded) {
      const username = {
        user_name: props.userName,
      };
      service.removeImage(username).then((res) => { });
    }
  };

  //upload image
  const uploadImgaeForEwalletLoad = () => {
    if (state.theamount.length === 0) {
      setState((prev) => ({
        ...prev,
        enableFinish: false,
        showAlert: true,
        alertType: "danger",
        AlertMessage: t("Common.entnum"),
      }));
    }

    if (valid.checkAmVal === true) {
      const formData = new FormData();
      formData.append("file", state.selectedFile);
      formData.append("user_name", props.userName ?? "");
      formData.append("type", props.type ?? "subscription_renewal");
      formData.append("amount", state.theamount);
      console.log(`window.location.pathname ${window.location.pathname}`);
      if (window.location.pathname.includes("/replica_register")) {
        service.upload_repllicaReciept(formData).then((res) => {
          if (res.status) {
            setState((prev) => ({
              ...prev,
              enableFinish: true,
              showAlert: true,
              alertType: "success",
              AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
              imageUploaded: true,
            }));
          } else {
            setState((prev) => ({
              ...prev,
              enableFinish: false,
              showAlert: true,
              alertType: "danger",
              AlertMessage: t("Common.error_file_upload"),
            }));
          }
        });
      }
      service.upload_reciept(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            enableFinish: true,
            showAlert: true,
            alertType: "success",
            AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
            imageUploaded: true,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            enableFinish: false,
            showAlert: true,
            alertType: "danger",
            AlertMessage: t("Common.error_file_upload"),
          }));
        }
      });
    }
  };
  return (
    <div className={style.MianContent}>
      <Alert show={state.showAlert} variant={state.alertType} dismissible>
        {state.AlertMessage}
      </Alert>
      <Form
        onSubmit={(e) => {
          setState((prev) => ({
            ...prev,
            loader: true,
          }));
          window.location.pathname.includes("/register") ||
            window.location.pathname.includes("/referral_register")
            ? props.submit(e, "", "payment")
            : props.submit(e, state.theamount);
        }}
      >
        {window.location.pathname.includes("/load") && (
          <Form.Group>
            <Form.Label>{t("Common.mobileno")}</Form.Label>
            <Form.Control
              type="text"
              inputClass={`${currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
                }`}
              className={style.bankDetails}
              readOnly
              value={props.details.mobile}
            /><br />
            <Form.Label>{t("Common.withdrawalamount")}</Form.Label>
            <Form.Control
              as="input"
              inputClass={`${currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
                }`}
              className={style.bankDetails}
              onChange={changeHandler}
            />
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label className={style.required}>
            {t("Common.selectRecipt")}
          </Form.Label>
          <Form.File onChange={onFileChange} />
          <Form.Text className={style.textdanger}>
            {t("Common.allowTypes")}
          </Form.Text>
        </Form.Group>
        {state.url && (
          <Form.Group>
            <Image src={state.url} className={style.recieptPreview} />
            <Button className={style.RecieptRemoveButton} onClick={removeImage}>
              <i className="fa fa-times"></i>
              Remove
            </Button>
          </Form.Group>
        )}
        <Form.Group className={style.Buttons}>
          {window.location.pathname.includes("/loadEwallet") ? (
            <Button
              onClick={uploadImgaeForEwalletLoad}
              // onClick={validAmount}
              disabled={!state.url}
              className={style.uploadButton}
            >
              {t("Button.senddata")}
            </Button>
          ) : (
            <Button
              onClick={uploadImgae}
              disabled={!state.url}
              className={style.uploadButton}
            >
              <i className="fa fa-arrow-circle-o-up"></i>
              {t("Button.upload")}
            </Button>
          )}
          <Button
            disabled={!(state.theamount.length > 0 && state.enableFinish)}
            type="submit"
            className={style.NextButton}
          >
            {props.loader ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : (
              ""
            )}
            {t("Button.finish")}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default MobileTransfer;

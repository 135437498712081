import { BASE_URL } from "../../configuration/apiconfig"
import API from '../../api/api'

export default class ProductWalletservice{
    key=localStorage.getItem('apikey')
    ProductWallet=async()=>{
        return await API.API.get('productWallet/productWalletTile').then((res)=>{
            if(res.status){
                return res.data
            }
            else{
                return res
            }
        }).catch((err)=>console.log(err))
    }
    statement = async (start=0,length=10) =>{
        return  await API.API.get('productWallet/productWalletStatementTable?length='+length+'&start='+start+'')
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }

    history = async(start=0,length=10,direction="",startDate="",endDate="",keys="",order="") =>{
        return  await API.API.get('productWallet/productWalletHistoryTable?direction='+direction+'&length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&order='+keys+'&type='+order)
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }

    // wallet =  async(start,length) =>{
    //     return  await API.API.get('product_wallet/purchase_wallet_table?length='+length+'&start='+start+'')
    //     .then((res)=>{
    //         if(res.status===200){
    //             return  res.data
    //          }
    //          else{
    //            return res;
    //          }
    //     }).catch((error)=>console.log(error))
    // }
    earnigs = async(start=0,length=10,direction="",startDate="",endDate="",keys="",order="")=>{
        return  await API.API.get('productWallet/userEarningsTable?direction='+direction+'&length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&order='+order)
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }

    fundTransfer  = async(payload) =>{
        return  await API.API.post('productWallet/productToCashTransfer',payload)
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }

    GadgetfundTransfer  = async(payload) =>{
        return  await API.API.post('productWallet/productToGadgetTransfer',payload)
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }

    AdminfundTransfer  = async(payload) =>{
        return  await API.API.post('productWallet/transferToAdmin',payload)
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }
}
import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import API from "../../api/api";


export default class PayinService {
key = localStorage.getItem("apiKey");

PayinTiles = async () => {
    return await API.API.get("payin/payin_tiles")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ApprovedTable = async (start, length, order = "", dierection = "asc",walletType="") => {
    return await API.API.get(
      "payin/approved_list?length=" +
        length +
        "&start=" +
        start +
        "&order=" +
        order +
        "&direction=" +
        dierection+
        "&walletType="+
        walletType
    )
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  PendingTable = async (start, length, order = "", dierection = "asc",walletType="") => {
    return await API.API.get(
      "payin/pending_list?length=" +
        length +
        "&start=" +
        start +
        "&order=" +
        order +
        "&direction=" +
        dierection +
        "&walletType="+
        walletType
    )
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };


  RejectedTable = async (start, length, order = "", dierection = "asc",walletType="") => {
    return await API.API.get(
      "payin/rejected_list?length=" +
        length +
        "&start=" +
        start +
        "&order=" +
        order +
        "&direction=" +
        dierection+
        "&walletType="+
        walletType
    )
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  getPaymentData = async (type) => {
    return await API.API.get("payin/payin_request")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };


  PayinRequest = async (payload) => {
    return await API.API.post("payin/payin_request",payload)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  upload_recipt = async (payload) => {
    return await API.API.post("payin/uploadReciepts",payload)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

   


  

}

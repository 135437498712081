import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//components
import { useSelector } from "react-redux";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";

//service
import EwalletService from "../../../service/ewallet/Ewallet";
import ProfileService from "../../../service/profile";
import companyName from "../../../store/mobxStore/companyName";

import style from "../../components/registration/registration.module.scss";
import Paymentview from "../../components/payin/paymentview";
import PayinService from "../../../service/payin";
import { useHistory } from "react-router-dom";

const mapStateToProps = (state) => ({ lang: state.Lang });

const PayinLoad = (props) => {
  const Currency = useSelector((state) => state.curr);
  const service = new PayinService();
  const ewalservice = new EwalletService();
  const history = new useHistory();

  const [state, setState] = useState({
    PaymentMethods: [],
    selectedPayment: "",
    amount: 0,
    userName: "",
    product: "",
    loader: true,
  });

  const { t } = props;

  useEffect(() => {
    loadPaymentData();
  }, []);

  const loadPaymentData = () => {
    service.getPaymentData().then((res) => {
      if (res.status) {
        setState((prevstate) => ({
          ...prevstate,
          PaymentMethods: res.data,
          selectedPayment: res.data[0],
          loader: false,
        }));
      }
    });
  };

  const submittheForm = (event, amount) => {
    if (event.hasOwnProperty("defaultPrevented")) {
      event.preventDefault();
    }

    let formData = {
      payment_method: state.selectedPayment.code,
      payin_amount: amount,
    };

    service.PayinRequest(formData).then((res) => {
      if (res.status) {
        history.push("payin");
      }
    });
  };

  const paymentmethodchangehere = (tab) => {
    setState((prevState) => ({
      ...prevState,
      selectedPayment: state.PaymentMethods[tab],
    }));
  };

  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.Payin")}
        </title>
      </Helmet>
      <PageTitle title="Payin" buttonOn={false} />

      {state.loader ? (
        <Spinner />
      ) : (
        <div className={style.MainContainer}>
          <Card>
            <Card.Body>
              <Paymentview
                // productAmount={state.amount}
                data={state.PaymentMethods}
                // prevButtonClick={prevPage}
                submit={submittheForm}
                paymentMethodChange={paymentmethodchangehere}
                // userName={state.userName}
                // product={state.product}
                // ewallet={getEwalletData}
                // epin={getEpinData}
                // getPaypalData={paypalData}
                payment_type="ewallet_load"
                // loader={loading}
              />
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(PayinLoad))
);

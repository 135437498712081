import React from 'react'
import { Helmet } from 'react-helmet'
import companyName from '../../../store/mobxStore/companyName'
import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../components/common';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment'
import { useHistory } from 'react-router';
import ReportService from '../../../service/CommissionReport/CommissionReport';
import Spinner from '../../shared/Spinner';
import Commissionreport from '../../components/CommissionReport/CommissionReport';
function CommissionReport() {
    const { t } = useTranslation();
    const service = new ReportService();
    const history = useHistory();
    const [state, setState] = useState({
        loader: false,
        filter: {
            start: moment().startOf('month'),
            end: moment(),
            label: moment().startOf('month').format('MMMM D,YYYY') + '-' + moment().format('MMMM D,YYYY')
        },
        report: {
            totalRows: 0,
            data: [],
            pending: true,
            perPage: 10,
            inPage: 1

        }
    })
    useEffect(() => {
        loadData(state.report.inPage, state.report.perPage, state.filter.start.format('Y-MM-DD'), state.filter.end.format('Y-MM-DD'));
    }, []);

    const loadData = (inPage = state.report.inPage, perPage = state.report.perPage, startDate = '', endDate = '') => {
        let start = (inPage - 1) * perPage;
        service.getCommissionReport(start, perPage, startDate, endDate).then((res) => {   
            console.log('response',res.data)       
            if (res.status) {
                console.log('status',res.status)
                setState(prevState => ({
                    ...prevState,
                    report: {
                        ...prevState.report,
                        totalRows : res.data.total_row,
                        data : res.data.data,
                        pending : false

                    }
                }))
                console.log('tableDtaa', state.report.data)
            }
            // else {
            //     if (res.error.code === 1002) {
            //         history.push('/logout');
            //     } else if (res.error.code === 1057) {
            //         history.push({
            //             pathname: '/dashboard',
            //             state: {
            //                 error: true,
            //                 message: 'permissionDenied'
            //             }
            //         })
            //     }
            // }
        })
    }
   

    //filter change--start----
    const filterChange = (start, end) => {
        setState((prev) => ({
            ...prev,
            filter: {
                ...prev.filter,
                start: start,
                end: end,
                label: start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
            }
        }))
    }
    //filter change--end----

    const resetButton = () => {
        setState((prev) => ({
            ...prev,
            report: {
                ...prev.report,
                perPage: 10,
                inPage: 1
            }
        }))
        loadData(1, 10)
    }
    const submitFilter = () => {
        let startDate = state.filter.start.format('Y-MM-DD')
        let endDate = state.filter.end.format('Y-MM-DD')
        loadData(state.report.inPage, state.report.perPage, startDate, endDate)
    }
    const paginationchange = (inPage, perPage = state.report.perPage) => {
        setState((prev) => ({
            ...prev,
            report: {
                ...prev.report,
                inPage: inPage,
                perPage: perPage
            }
        }))
        let StartDate = state.filter.start.format('Y-MM-DD');
        let endDate = state.filter.end.format('Y-MM-DD');
        loadData(inPage, perPage, StartDate, endDate);
    }
    if (state.loader) {
        return <Spinner />
    }
    return (
        <div className='h-100'>
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.commissionreport')}</title>
            </Helmet>
            <PageTitle title="commissionreport" />
            <Commissionreport
                {...state}
                changeDate={filterChange}
                resetButton={resetButton}
                submitFilter={submitFilter}
                paginationchange={paginationchange}
            />
        </div>
    )
}

export default CommissionReport
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  // Route,Switch
} from 'react-router-dom';
import './App.scss';
import './style/customstyle.css';
import RouteMap from './route';
import { createBrowserHistory } from 'history';
import { ToastContainer } from "react-toastify";
// import { createUseStyles } from "react-jss";


function getFaviconEl() {
  return document.getElementById("favicon");
}
function getAppFavicon() {
  return document.getElementById("fav");
}
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});
function getAppTitle() {
  return document.getElementById('companyname')
}
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logo: '',
      title: ''
    }
  }

  componentDidMount() {
    fetch(
      // "http://192.168.9.9:5002/api/common/app_info", {
        "http://199.192.29.234:5000/api/common/app_info", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
         'api-key': '1066_'
        //'api-key': '1055_'
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          logo: json.data.company_info.favicon,
          title: json.data.company_info.company_name
        });
        this.handleGoogle();
      })
  }
  handleGoogle() {
    // const favicon = getFaviconEl(); // Accessing favicon element
    // const fav = getAppFavicon();
    // favicon.href = "https://backoffice.infinitemlmsoftware.com/uploads/images/logos/" + this.state.logo;
    // fav.href = "https://backoffice.infinitemlmsoftware.com/uploads/images/logos/" + this.state.logo;
  };
  handleApptitle() {
    getAppTitle()
    document.title = this.state.title;

  }
  render() {
    return (
      <Router>
        <ToastContainer limit={2}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          autoClose={5000} />
        <RouteMap />
      </Router>
    )
  }
}
export default App;

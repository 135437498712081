import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class GenealogyService{
    key =localStorage.getItem('apiKey')
    //get the referral members
async getTreelist(userName='',user_id=''){
        const response = await fetch(`${BASE_URL}tree/genealogy_tree?user_name=${userName}&user_id=${user_id}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }

    //sponsor tree
    async getSponsorTree(username,userId){
        const response = await fetch(`${BASE_URL}tree/sponsor_tree?user_name=${username}&user_id=${userId}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
}
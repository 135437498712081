import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './profile.module.scss';
import { FormInputControl } from '../common';
import LoginService from '../../../service/Auth/Login';
import ProfileService from '../../../service/profile';
import { useHistory } from 'react-router-dom';
import { PasswordValidation } from '../../hook/passwordValidation';
import { usePopperTooltip } from 'react-popper-tooltip';
import { animated, useTransition } from 'react-spring';
function ChangePassword({ policy, ...props }) {
    const [controlledVisible, setControlledVisible] = useState(false);
    const { t } = useTranslation();
    const [password, setPassword] = useState({
        password: "",
        policy: "",
    });
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
    } = usePopperTooltip({
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
        trigger: 'focus'
    });
    const transitions = useTransition(controlledVisible, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });
    const [validLength, hasNumber, upperCase, lowerCase, specialChar, mixedcase] = PasswordValidation({
        password: password.password,
        policy: policy
    });
    const [passwordPolicy, setPasswordPolicy] = useState({
        uppercase: upperCase,
        lowercase: lowerCase,
        sp_char: specialChar,
        number: hasNumber,
        min_length: validLength,
        mixedcase: mixedcase
    })
    useEffect(() => {
        setPasswordPolicy({
            uppercase: upperCase,
            lowercase: lowerCase,
            sp_char: specialChar,
            number: hasNumber,
            min_length: validLength,
            mixedcase: mixedcase
        })
    }, [validLength, hasNumber, upperCase, lowerCase, specialChar, mixedcase])
    const InitalData = [
        { key: 'currentPassword', field: 'current_password' },
        { key: 'newPassword', field: 'new_password' },
        { key: 'confirmPassword', field: 'password_confirmation' }].map(items => {
            return {
                code: items.key,
                form_field: items.field,
                value: '',
                type: 'password',
                error: {
                    field: '',
                    error: ''
                }
            }
        });
    //captcha data
    const CaptchaData = {
        code: 'captcha',
        value: '',
        type: 'text',
        error: {
            field: '',
            error: ''
        }
    }
    const history = useHistory();
    const [state, setState] = useState({
        passwordData: InitalData,
        captcha: CaptchaData,
        captchaImage: '',
        propsData: {
            label: '',
            icon: "",
            subtittle: "",
            buttonSubmit: "",
            fadeClass: true
        }
    });
    // const [captchaimage,setCaptchaimage] =useState('')
    const changehandler = (e, key = '') => {
        const { name, value } = e.target;
        const { passwordData, captcha } = state;
        if (key === 'captcha') {
            captcha.value = value;
            captcha.error = {
                error: '',
                field: ''
            }
            setState(prev => ({
                ...prev,
                captcha: captcha
            }));
        } else {
            if (name === 'newPassword') {
                setPassword({
                    ...password,
                    password: value,
                    policy: policy
                })
            }
            const changeData = passwordData.map((field) => {
                if (field.code === name) {
                    return {
                        ...field,
                        value: value,
                        error: {
                            error: '',
                            field: ''
                        }
                    }
                }
                return {
                    ...field
                }
            })
            setState(prev => ({
                ...prev,
                passwordData: changeData
            }));
        }
    }
    useEffect(() => {
        setState(prev => ({
            ...prev,
            propsData: {
                label: props.modalType,
                icon: props.icon,
                subtittle: "youCanResetYourPassword",
                buttonSubmit: "changepassowrd"
            }
        }))
    }, [props.modalType, props.icon]);
    //onsubmit form
    const onSubmit = (e) => {
        const service = new LoginService();
        e.preventDefault();
        let isValid = true;
        let current_password = ''
        const { passwordData, propsData, captcha } = state;
        let NewData = [];
        if (propsData.label === 'forgotTransactionPassword') {
            if (!captcha.value) {
                isValid = false
                captcha.error = {
                    error: 'validation.required',
                    field: {
                        field: 'captcha'
                    }
                }
            }
        } else {
            let minLength = policy.disableHelper ? policy.min_length : propsData.label === 'changePassword' ? 6 : 8;//password min length
            NewData = passwordData.map(item => {
                if (item.error.error) {
                    isValid = false;
                } else if (!item.value) {
                    isValid = false;
                    return {
                        ...item,
                        error: {
                            error: 'validation.required',
                            field: {
                                field: item.code
                            }
                        }
                    }
                } else if (item.code === 'newPassword') {
                    current_password = item.value;
                    if (item.value.length < minLength) {
                        isValid = false;
                        return {
                            ...item,
                            error: {
                                error: 'validation.min_length',
                                field: {
                                    field: item.code,
                                    length: minLength
                                }
                            }
                        }
                    } else if (item.value.length > 32) {
                        isValid = false;
                        return {
                            ...item,
                            error: {
                                error: 'validation.max_length',
                                field: {
                                    field: item.code,
                                    length: 32
                                }
                            }
                        }
                    }
                } else if (item.code === 'confirmPassword') {
                    if (current_password !== item.value) {
                        isValid = false;
                        return {
                            ...item,
                            error: {
                                error: 'validation.mismatch'
                            }
                        }
                    }
                }
                return {
                    ...item
                }
            })
        }
        if (isValid) {
            let FormData = {};
            passwordData.map(field => {
                FormData[field.form_field] = field.value;
                return true
            });
            if (propsData.label === 'changePassword') {
                service.ChangePasword(FormData).then((res => {
                    if (res.status) {
                        props.setNotify('success', t('Common.success'), t('profile.passwordUpdatedSuccessfully'));
                        setState(prev => ({
                            ...prev,
                            passwordData: InitalData
                        }))
                    } else {
                        if (res.error.code === 1021) {
                            props.setNotify('danger', t('Common.error'), t('validation.incorrectPassword'));
                        }
                        else if (res.error.code === 1070) {
                            props.setNotify('danger', t('Common.error'), res.error.description);
                        }
                        else if (res.error.code === 1002) {
                            history.push('/logout')
                        }
                    }
                }))
            } else if (propsData.label === 'changeTransactionPassword') {
                service.ChangeTransactionPassword(FormData).then(res => {
                    if (res.status) {
                        props.setNotify('success', t('Common.success'), t('profile.transactionpasswordchangedsuccessfully'));
                        setState(prev => ({
                            ...prev,
                            passwordData: InitalData
                        }))
                    } else {
                        if (res.error.code === 1021) {

                            props.setNotify('danger', t('Common.error'), t('validation.incorrectPassword'));
                        } else if (res.error.code === 1015) {

                            props.setNotify('danger', t('Common.error'), t('validation.incorrectTransactionPassword'));
                        }
                        else if (res.error.code === 1002) {
                            history.push('/logout')
                        }
                    }
                })
            } else if (propsData.label === 'forgotTransactionPassword') {
                FormData = {
                    captcha: state.captcha.value
                }
                service.forgotTransactionPassword(FormData).then(res => {
                    if (res.status) {
                        props.showNotify('success', 'Common.success', 'validation.captchaSuccess');
                    } else {
                        if (res.error.code === 1002) {
                            history.push('/logout')
                        } else if (res.error.code === 1004) {
                            props.setNotify('danger', t('Common.error'), t('validation.invalidCaptcha'));
                        }
                    }
                })
            }

        } else {
            setState(prev => ({
                ...prev,
                passwordData: NewData
            }));
            props.setNotify('danger', t('Common.error'), t('validation.checkValueYouHaveSubmitted'));
        }
    }

    //forgot button click 
    const clickForgot = () => {
        reloadCaptcha()
        setState(prev => ({
            ...prev,
            propsData: {
                label: 'forgotTransactionPassword',
                icon: 'fa fa-envelope-open',
                subtittle: "noteSendConfirmationMailFollowThatInstruction",
                buttonSubmit: "requestSend"
            }
        }))
    }
    //reloadCaptcha
    const reloadCaptcha = () => {
        const captchaservice = new ProfileService();
        captchaservice.getCaptcha().then((res) => {
            let outside = URL.createObjectURL(res);
            setState(prev => ({
                ...prev,
                captchaImage: outside
            }))
        })
    }

    //onClose modal 
    const closeModal = () => {
        setState(prev => ({
            ...prev,
            passwordData: InitalData,
            propsData: {
                ...prev,
                fadeClass: false
            }
        }))
        props.onClose()
    }
    //blurHandler
    const onBlurHandler = (e, key) => {
        if (key === 'newPassword') {
            let Policyvalid = true;
            let NewData = state.passwordData.map((items) => {
                if (items.code === 'newPassword') {
                    Object.keys(policy).map(function (key, index) {
                        if (key !== 'disableHelper') {
                            if (!passwordPolicy[key]) {
                                Policyvalid = false
                            }
                        }
                        return false
                    })
                    if (!Policyvalid) {
                        return {
                            ...items,
                            error: {
                                error: 'validation.passwordPolicyInvalid'
                            }
                        }
                    }
                }
                return items
            })
            setState(prev => ({
                ...prev,
                passwordData: NewData
            }))
        }
    }
    return (
        <Modal
            show={props.show}
            backdropClassName={`modalBackDrops`}
            contentClassName={style.ModalContent}
            onHide={() => { }}
            dialogClassName={`animate__animated ${!state.propsData.fadeClass ? 'animate__fadeInDown' : 'animate__fadeInUp'}`}
        >
            <Modal.Header className={style.ModalHeader}>
                <h3>
                    <i className={`${state.propsData.icon} fa-3x`}></i>
                </h3>
                <Modal.Title className={style.ModalTitle}>{t(`Common.${state.propsData.label}`)}?</Modal.Title>
                <p>{t(`profile.${state.propsData.subtittle}`)}</p>
            </Modal.Header>
            <Modal.Body className={style.ModalBody}>
                <Form onSubmit={onSubmit} noValidate>
                    {
                        state.propsData.label !== 'forgotTransactionPassword' ?
                            state.passwordData.map((field, index) => {
                                return <FormInputControl
                                    key={index}
                                    label={t(`profile.${field.code}`)}
                                    error={field.error}
                                    required={true}
                                    inputProps={{
                                        ref: (field.code === 'newPassword' && policy.disableHelper) ? setTriggerRef : null,
                                        type: field.type,
                                        name: field.code,
                                        value: field.value,
                                        onChange: changehandler,
                                        onBlur: (e) => { onBlurHandler(e, field.code) }
                                    }}
                                >
                                    {//password polizy
                                        field.code === 'newPassword' && policy.disableHelper &&
                                        transitions.map(({ item, key, props }) =>
                                            item && (
                                                <animated.div
                                                    key={key}
                                                    ref={setTooltipRef}
                                                    {...getTooltipProps({
                                                        className: 'tooltip-container',
                                                        style: props,
                                                    })}
                                                >
                                                    <div>
                                                        {
                                                            Object.keys(policy).map(function (key, index) {
                                                                if (key !== 'disableHelper') {
                                                                    return <p key={index}>
                                                                        {
                                                                            passwordPolicy[key] ?
                                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> :
                                                                                <i className="fa fa-times-circle" style={{ color: "#f05050" }}></i>
                                                                        }
                                                                        {t('validation.atleast_' + key, { count: policy[key] })}
                                                                    </p>
                                                                }
                                                                return false
                                                            })
                                                        }
                                                    </div>
                                                    <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                                                </animated.div>
                                            )
                                        )
                                    }
                                </FormInputControl>
                            }) :
                            <React.Fragment>
                                <Form.Group className={style.captcha}>
                                    <img src={state.captchaImage} alt="" />
                                </Form.Group>
                                <FormInputControl
                                    label={t("Common.changeCaptcha")}
                                    error={state.captcha.error}
                                    required={true}
                                    inputProps={{
                                        type: state.captcha.type,
                                        name: state.captcha.code,
                                        value: state.captcha.value,
                                        onChange: (e) => { changehandler(e, 'captcha') }
                                    }}
                                    labelProps={{
                                        onClick: reloadCaptcha
                                    }
                                    }
                                />
                            </React.Fragment>
                    }
                    <div className={style.actionButton}>
                        <Button variant="secondary" onClick={closeModal} className={style.cancelButton}>
                            {t('Button.cancel')}
                        </Button>
                        <Button type="submit" className={style.changeButton}>
                            {t('Button.' + state.propsData.buttonSubmit)}
                        </Button>
                    </div>
                    {/* {
                        state.propsData.label === 'changeTransactionPassword' &&
                        <div className={style.forgotTag} onClick={clickForgot}>
                            {t('profile.forgotTransactionPassword')}
                        </div>
                    } */}
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ChangePassword

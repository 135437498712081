import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Spinner from "../../shared/Spinner";
import { IMG_URL } from "../../../configuration/apiconfig";
import { CreateGuest } from "../../components/party";
import PartySetupService from "../../../service/party/setUp";
import companyName from "../../../store/mobxStore/companyName";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import { withRouter } from "react-router-dom";

function AddGuest({ t }) {
  let service = new PartySetupService();
  let history = useHistory();

  const [state, setState] = useState({
    loading: false,
    data: {
      host: [],
      guest: [],
    },
    notify: {
      show: false,
      message: "",
      type: "",
      header: "",
    },
    form: {
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      email: "",
      id: "",
      FormError: {
        firstName: "",
        lastName: "",
        address: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        email: "",
      },
    },
    partyContent: {
      name: "",
      image: IMG_URL + "uploads/images/party_image/party_image.png",
      uploadFile: "",
      host: "old",
      oldHost: "",
      country: [],
      states: [],
      from: "",
      startTime: "",
      to: "",
      endTime: "",
      address: "host_address",
      FormError: {
        name: "",
      },
      form: {
        firstName: "",
        lastName: "",
        address: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        email: "",
        FormError: {
          firstName: "",
          lastName: "",
          address: "",
          phone: "",
          country: "",
          state: "",
          city: "",
          zip: "",
          email: "",
        },
      },
      newAddress: {
        firstName: "",
        lastName: "",
        address: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        email: "",
        FormError: {
          firstName: "",
          lastName: "",
          address: "",
          phone: "",
          country: "",
          state: "",
          city: "",
          zip: "",
          email: "",
        },
      },
    },
  });
  const FieldSet = [
    {
      code: "firstName",
      minLength: 3,
      maxLength: 25,
      regex: "^[a-zA-Z ]*$",
    },
    {
      code: "lastName",
      minLength: 3,
      maxLength: 32,
      regex: "^[a-zA-Z ]*$",
    },
    {
      code: "zipCode",
      minLength: 3,
      maxLength: 10,
      regex: "^[0-9-+]+$",
    },
    {
      code: "city",
      minLength: 3,
      maxLength: 32,
      regex: "^[a-zA-Z ]*$",
    },
    {
      code: "phoneNumber",
      minLength: 5,
      maxLength: 10,
      regex: "^[0-9]{15}$",
    },
    {
      code: "email",
      minLength: "",
      maxLength: "",
      regex: "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$",
    },
  ];

  useEffect(() => {
    service.getCountry().then((res) => {
      if (res.status) {
        console.log("country", res.data.country);
        setState((prevState) => ({
          ...prevState,
          partyContent: {
            ...state.partyContent,
            country: res.data.country,
          },
        }));
      } else {
        history.push("/logout");
      }
    });
  }, []);

  const changeHandler = (e, isNew = false, key = "") => {
    const { name, value } = e.target;
    setState({
      ...state,
      form: {
        ...state.form,
        [name]: value,
        FormError: {
          [name]: {
            error: "",
          },
        },
      },
    });
    if (name === "country") {
      service.countryChange(value).then((res) => {
        if (res.status) {
          setState((prevState) => ({
            ...prevState,
            partyContent: {
              ...prevState.partyContent,
              states: res.data,
            },
          }));
        } else {
          history.push("/logout");
        }
      });
    }
    if (isNew) {
    } else {
      setState((prevState) => ({
        ...prevState,
        partyContent: {
          ...state.partyContent,
          [name]: value,
        },
      }));
    }
  };
  const viewGuest = (id) => {
    service.viewGuest(id).then((res) => {
      if (res.status) {
        setState({
          ...state,
          form: {
            ...state.form,
            firstName: res.data.result[0].name,
            lastName: res.data.result[0].last_name,
            address: res.data.result[0].address,
            phone: res.data.result[0].phone,
            country: res.data.result[0].country,
            state: res.data.result[0].state,
            city: res.data.result[0].city,
            zip: res.data.result[0].zip,
            email: res.data.result[0].email,
            id: res.data.result[0].id,
          },
        });
      }
    });
  };
  const onsubmit = () => {
    let isvalid = true;
    let FormError = state.form;
    let formdata = {
      firstname: state.form.firstName,
      lastname: state.form.lastName,
      address: state.form.address,
      country: state.form.country,
      //"state":state.form.state ? state.form.state :"",
      city: state.form.city,
      zip: state.form.zip,
      phone: state.form.phone,
      email: state.form.email,
      id: state.form.id,
    };
    if (state.form.state) {
      formdata["state"] = state.form.state;
    }
    Object.keys(state.form).forEach((key, index) => {
      if (state.form[key] == "" && key != "state" && key != "id") {
        isvalid = false;
        FormError.FormError[key] = {
          error: "validation.required",
          field: {
            field:
              key == "zip" ? "zipCode" : key == "phone" ? "phoneNumber" : key,
          },
        };
      } else {
        FieldSet.map((obj) => {
          if (state.form[key] && key != "state") {
            if (obj.code == key) {
              if (obj.minLength) {
                if (state.form[key].length < obj.minLength) {
                  isvalid = false;
                  FormError.FormError[key] = {
                    error: "validation.min_length",
                    field: {
                      field:
                        key == "zip"
                          ? "zipCode"
                          : key == "phone"
                          ? "phoneNumber"
                          : key,
                      length: obj.minLength,
                    },
                  };
                }
              }
              if (obj.maxLength) {
                if (state.form[key].length > obj.maxLength) {
                  isvalid = false;
                  FormError.FormError[key] = {
                    error: "validation.max_length",
                    field: {
                      field:
                        key == "zip"
                          ? "zipCode"
                          : key == "phone"
                          ? "phoneNumber"
                          : key,
                      length: obj.maxLength,
                    },
                  };
                }
              }
              if (obj.regex) {
                let Rgex = new RegExp(obj.regex);
                if (!Rgex.test(state.form[key])) {
                  isvalid = false;
                  FormError.FormError[key] = {
                    error: "validation.invalid",
                    field: {
                      field:
                        key == "zip"
                          ? "zipCode"
                          : key == "phone"
                          ? "phoneNumber"
                          : key,
                    },
                  };
                }
              }
            }
          }
        });
      }
    });
    if (isvalid) {
      service.AddGuest(formdata).then((res) => {
        console.log(`response ${JSON.stringify(res)}`)
        if (res.status) {
          setState({
            ...state,
            form: {
              firstName: "",
              lastName: "",
              address: "",
              phone: "",
              country: "",
              state: "",
              city: "",
              zip: "",
              email: "",
              id: "",
              FormError: {
                firstName: "",
                lastName: "",
                address: "",
                phone: "",
                country: "",
                state: "",
                city: "",
                zip: "",
                email: "",
              },
            },
            notify: {
              ...state.notify,
              show: true,
              message: "validation.guestAdded",
              type: "success",
              header: "Common.success",
            },
          });
        } else {
          setState({
            ...state,
            notify: {
              ...state.notify,
              show: true,
              message: "validation.guestError",
              type: "error",
              header: "Common.error",
            },
          });
        }
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...state.form,
          ...FormError,
        },
      }));
    }
  };

  const removeImage = () => {
    setState({
      partyContent: {
        ...state.partyContent,
        uploadFile: "",
        image: IMG_URL + "uploads/images/party_image/party_image.png",
      },
    });
  };
  const changeFile = (e) => {
    let reader = new FileReader();
    reader.onload = (event) => {
      setState((prevState) => ({
        ...prevState,
        partyContent: {
          ...state.partyContent,
          uploadFile: e.target.files[0],
          image: event.target.result,
        },
      }));
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const createParty = () => {
    let isvalid = true;
    let content = state.partyContent;
    //validation the party name
    if (content.name === "") {
      isvalid = false;
      content.FormError.name = {
        error: "validation.required",
        field: {
          field: "partyName",
        },
      };
    }
    //validation the step Two
    if (content.host === "old") {
    }
    if (isvalid) {
    } else {
      setState({
        partyContent: {
          ...content,
        },
      });
    }
  };
  if (state.loading) {
    return <Spinner />;
  }
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.addGuest")}
        </title>
      </Helmet>
      <PageTitle title="addGuest" buttonOn={false} />
      <CreateGuest
        {...state}
        changeHandler={changeHandler}
        onsubmit={onsubmit}
        viewGuest={viewGuest}
      />
    </div>
  );
}
export default withTranslation()(withRouter(AddGuest));

import React, { useEffect, useState } from "react";
import EwalletService from '../../../service/ewallet/Ewallet';
import Table from 'react-bootstrap/Table';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

//compoenents
import { DropDown,PendingTable,PaidTable,ApprovedTable,RejectedTable} from './';
import {CurrencyDecimalFormat} from '../../helper'
//service
import AppInfoservice from '../../../service/common/Appinfo';



//style
import {Row,Col} from 'react-bootstrap'
  
export  class ComponentToPrint extends React.PureComponent{

    
  
    constructor(props) {
        super(props);
        

      let value;

     
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()} , ${current.getHours()}:${current.getMinutes()}`;
      // const time = current.getHours() + ':' + current.getMinutes();
    
      this.state = {
        earnings:{},
        loading:true,
        date: date,
    };
    }
    
    
    
    columns = [
        {
          name: this.props.t('payout.requestedDate'),
          selector: row=>row.requested_date,
        //   width : "30%"
        },
        {
          name: this.props.t('Common.amount'),
          selector: row=>row.payout_amount,
          sortable: true,
          cell: (row) => <span className={`${this.style.amount} ${this.style.badge}`}>{this.Currency.currentCurr} {CurrencyDecimalFormat(row.payout_amount*this.Currency.value,this.Currency.precision)}</span>
        },
        {
            name: this.props.t('Common.eWalletBalance'),
            selector: row=>row.ewallet_balance,
            sortable: true,
            cell: (row) => <span className={`${this.style.balanceAmount} ${this.style.badge}`}>{this.Currency.currentCurr} {CurrencyDecimalFormat(row.ewallet_balance*this.Currency.value,this.Currency.precision)}</span>
          },
      ];
          
    
 

   
  
    render(){ 
    let value;
    
        return (
             <div style={{padding:60}}>
        <Row>
        <Col>
        {this.state.date}
        </Col>
          <Col>
            <h5 style={{textAlign:"center"}}>{this.props.heading}</h5>
          </Col>
        </Row>
        
        
        
        <Row style={{marginBottom:50}}>
        <Col span={8}>
       
     
        <img src={this.props.company.logo} alt="Logo" />
        <div>COMPANY NAME : {this.props.company.comapny_name}</div>
        <div>COMPANY ADDRESS : {this.props.company.company_address}</div>
        <div>TELEPHONE : {this.props.company.phone}</div>
        <div>EMAIL : {this.props.company.email}</div>
        </Col>
        </Row>

          <div>

          <Table striped hover>
      <thead>
        
        <tr>
       { this.props.data[0] && Object.keys(this.props.data[0]).map((item,i)=>(
       value = item === 'requested_date'?"payout.requestedDate":item === 'ewallet_balance'?"Common.eWalletBalance":item === 'payout_amount'?"Common.amount":item === 'amount'?"Common.amount":item === 'approved_date'?"payout.approvedDate":item === 'payout_method'?"payout.paymentMethod":
       item === 'paid_date'?"payout.paidDate":item === 'requested_date'?"payout.rejectedDate":item === 'row.rejected_date'?"payout.requestedDate":item === ''?"payout.requestedDate":""
       ,
      

        <th key={i}>{this.props.t(value)}</th>
       ))}
        </tr>
      </thead>
      <tbody>
   
        {this.props.data && this.props.data.map((obj,index)=>{
            return (
                <tr key={index}>
                  { Object.keys(this.props.data[0]).map((item,i)=>{
                    if(obj[item] > 0){
                      return   <td>{this.props.Currency.currentCurr}{(parseFloat(obj[item]) * this.props.Currency.value).toFixed(this.props.Currency.precision)}</td>
                    }
                      else return <td>{obj[item]}</td>
                  })}

                   
            
        {/* <td key={i}>{console.log("obj",obj,"item",item,"final",obj.item)}{obj.item}</td> */}
       {/* ))} */}
                    {/* <td>{obj.paid_date}</td>
                    <td>{obj.amount}</td>
                    <td>{obj.payout_method}</td>
                   */}
                 
                   
                 </tr>
    
            )
             
    
    
        })} 
      </tbody>
    </Table>
          </div>
          </div>
        );
   
}

}




const PayoutContentPrintView = React.forwardRef((props, ref) => {

   const Currency = useSelector(state => state.curr);
  const {t}  = useTranslation();
 
        const [companyDetails,setCompanyDetails]= useState({})


   const service = new AppInfoservice();

const  value = props.ContentData.key === 'pending'?"pending":props.ContentData.key === 'approved'?"approved":props.ContentData.key === 'paid'?"paid":props.ContentData.key === 'rejected'?'rejected':""

const data = props.ContentData[value].data
const heading = value === 'pending'?"Payout Pending":value === 'approved'?"Payout Approved":value === 'paid'?"Payout Approved Paid":value === 'rejected'?'Payout Rejected':""

  useEffect(()=>{
     service.Appinfo().then((res)=>{
       if(res.status){
         setCompanyDetails((prevState) => ({
           ...prevState,
           logo : res.data.company_info.login_logo,
           comapny_name : res.data.company_info.company_name,
           company_address : res.data.company_info.company_address,
          phone : res.data.company_info.phone ,
           email : res.data.company_info.email

        })) 

       
       }
     
   })
   },[])



    return <ComponentToPrint ref={ref}  data={data} company={companyDetails} heading={heading} value={value} Currency ={ Currency} t ={t}  />
  
}
)
 export default PayoutContentPrintView ;

     
    
 



  
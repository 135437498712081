import React from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { CurrencyDecimalFormat } from "../../helper";
import style from "./payin.module.scss";
// const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={ref}
//         {...rest}
//       />
//       <label className="custom-control-label test" onClick={onClick} />
//     </div>
//   ));
function PendingTable(props) {
  const { t } = useTranslation(["translation", "payout"]);
  const Currency = useSelector((state) => state.curr);
  const columns = [
    {
      name: t("payout.requestedDate"),
      selector: (row) => row.requested_date,
      //   width : "30%"
    },
    {
      name: t("Common.amount"),
      selector: (row) => row.payout_amount,
      sortable: true,
      cell: (row) => (
        <span className={`${style.amount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.payout_amount * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },
    {
      name: t("Common.paymentMethod"),
      selector: (row) => row.payin_method,
      // sortable: true,
      // cell: (row) => (
      //   <span className={`${style.balanceAmount} ${style.badge}`}>
      //     {Currency.currentCurr}{" "}
      //     {CurrencyDecimalFormat(
      //       row.ewallet_balance * Currency.value,
      //       Currency.precision
      //     )}
      //   </span>
      // ),
    },
  ];

  //check box selection
  const selectedRowChange = (row) => {
    let selectedRows = row.selectedRows.map((items) => {
      return items.request_id;
    });
    props.cancelPopupHandler(selectedRows, false);
  };

  //cancel popup model
  const cancelPopup = () => {
    props.cancelPopupHandler([], true);
  };
  //onchange page
  const handlePageChange = (inPage) => {
    props.paginationchange(inPage, props.ContentData.perPage);
  };
  const handlePerRowsChange = (perPage, inPage) => {
    props.paginationchange(inPage, perPage);
  };
  const handleOnSort = ({ selector }, sortDirection) => {
    props.paginationchange(
      props.ContentData.inPage,
      props.ContentData.perPage,
      selector,
      sortDirection
    );
  };
  return (
    <div>
      <DataTable
        columns={columns}
        data={props.ContentData.data}
        highlightOnHover
        progressPending={props.ContentData.pending}
        progressComponent={<div>Loading</div>}
        persistTableHead
        noHeader
        pagination
        paginationServer
        responsive
        paginationTotalRows={props.ContentData.totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noDataComponent={t("Common.noDataRecordsToDisplay")}
        //onSort={handleOnSort}
        selectableRows
        onSelectedRowsChange={selectedRowChange}
        clearSelectedRows={props.ContentData.closePopUp}
      />
      {props.ContentData.selectedRows.length > 0 && (
        <div className={style.popupButtonArea}>
          <ul>
            <li>
              <span onClick={cancelPopup}>
                <i className="fa fa-times"></i>
              </span>
            </li>
            <li>
              <span className={style.CountRound}>
                {props.ContentData.selectedRows.length}
              </span>
              <span className={style.spanText}>
                {t("payout.itemsSelected")}
              </span>
            </li>
            <li>
              <Button
                variant="danger"
                className={style.CancelButton}
                onClick={props.onSubmit}
              >
                <i className="fa fa-trash-o"></i>
                {t("Button.cancel")}
              </Button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default PendingTable;

import { BASE_URL } from "../../configuration/apiconfig"
import API from '../../api/api'

export default class GadgetWalletservice{
    key=localStorage.getItem('apikey')
    GadgetWallet=async()=>{
        return await API.API.get('gadgetWallet/gadgetWalletTile').then((res)=>{
            if(res.status){
                return res.data
            }
            else{
                return res
            }
        }).catch((err)=>console.log(err))
    }
    statement = async (start=0,length=10) =>{
        return  await API.API.get('gadgetWallet/gadgetWalletStatementTable?length='+length+'&start='+start+'')
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }

    history = async(start=0,length=10,direction="",startDate="",endDate="",keys="",order="") =>{
        return  await API.API.get('gadgetWallet/gadgetWalletHistoryTable?direction='+direction+'&length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&order='+keys+'&type='+order)
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }

    wallet =  async(start,length) =>{
        return  await API.API.get('gadget_wallet/purchase_wallet_table?length='+length+'&start='+start+'')
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }
    earnigs = async(start=0,length=10,direction="",startDate="",endDate="",keys="",order="")=>{
        return  await API.API.get('gadgetWallet/userEarningsTable?direction='+direction+'&length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&order='+order)
        .then((res)=>{
            if(res.status===200){
                return  res.data
             }
             else{
               return res;
             }
        }).catch((error)=>console.log(error))
    }
    CashwalletFundTransfer=async(payload)=>{
        return await API.API.post('gadgetWallet/gadgetToCashTransfer',payload).then((res)=>{
            if(res.status){
                return res.data
            }
            else{
                return res
            }
        }).catch((err)=>console.log(err))
    }

    ProductWalletFundTransfer=async(payload)=>{
        return await API.API.post('gadgetWallet/gadgetToProductTransfer',payload).then((res)=>{
            if(res.status){
                return res.data
            }
            else{
                return res
            }
        }).catch((err)=>console.log(err))
    }
    adminWalletFundTransfer=async(payload)=>{
        return await API.API.post('gadgetWallet/transferToAdmin',payload).then((res)=>{
            if(res.status){
                return res.data
            }
            else{
                return res
            }
        }).catch((err)=>console.log(err))
    }

    
}
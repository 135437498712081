import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import { LeadContent } from "../../components/tools/";
import { EditLead } from "../../components/tools/";
import LeadService from "../../../service/tools/leads";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
// import AlertMessage from "../common/AlertMessage";
import { AlertMessage } from "../../components/common";

function Leasds(props) {
  let service = new LeadService();
  const [state, setState] = useState({
    leads: [], // list of leads
    keyword: "", // for filtering
    lead: null,
    loader:false, //for editing lead
    showEditModal: false,
    new_comment: "",
    new_status: "",
    lead_url: "",
    alert: {
      show: false,
      message: "",
      varient: "",
    },
  });

  const dissmissNotify = () => {
    setState({
      ...state,
      alert: {
        ...state.alert,
        show: false,
      },
    });
  };

  const handleEditAction = (id) => {
    let lead = state.leads.find((lead) => lead.id === id);
    setState((prevState) => ({
      ...prevState,
      lead,
      new_status: lead.status,
      showEditModal: true,
      leads: state.leads,
    }));
  };

  const handleClose = () => {
    setState({
      ...state,
      showEditModal: false,
      leads: state.leads,
    });
  };

  const keywordChange = (e) => {
    setState({
      ...state,
      keyword: e.target.value,
    });
  };

  const getLeads = (keyword) => {
    service.getLeads(keyword).then((res) => {
      if (res.status) {
        setState({
          ...state,
          showEditModal:false,
          keyword: keyword,
          leads: res.data.leads,
          lead_url: res.data.lead_url,
        });
      } else {
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1057) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

  const filterLeads = (e) => {
    e.preventDefault();
    getLeads(state.keyword);
  };
  const resetKeyword = () => {
    setState({
      ...state,
      keyword: "",
    });
    getLeads("");
  };

  const newComment = (e) => {
    setState((prevState) => ({
      ...prevState,
      new_comment: e.target.value,
      leads: state.leads,
    }));
  };

  const updateStatus = (e, id) => {
    let newdata = state.leads.map((item) => {
      if (item.id == id) {
        return {
          ...item,
          status: e.target.value,
        };
      }
      return {
        ...item,
      };
    });

    setState((prevState) => ({
      ...prevState,
      new_status: e.target.value,
      leads: newdata,
      lead: { ...prevState.lead, status: e.target.value },
    }));
  };

  const updateLead = (e) => {
    e.preventDefault();
    setState({
      ...state,
      loader:true
    })
    let formData = {
      comment: state.new_comment,
      status: state.new_status,
    };

    service.updateLead(formData, state.lead.id).then((res) => {
      if (res.status) {
        handleClose();
        getLeads(state.keyword);
        setState({
          ...state,
          showEditModal:false,
          new_comment:"",
          loader:false,
          alert: {
            show: true,
            message: "validation.leadUpdatedSuccessfully",
            varient: "success",
          },
        });
        window.scrollTo(0, 0);
      } else {
        if(res.error.code ===406){
          setState({
            ...state,
            loader:false,
            alert: {
              show: true,
              message: "validation.message_to_admin",
              varient: "danger",
            },
          });
        }
        if (res.error.code === 1002) {
          props.history.push("/logout");
        }
      }
    });
  };
  useEffect(() => {
    getLeads(state.keyword);
  }, []);
  const { t } = props;
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.leads")}
        </title>
      </Helmet>
      <PageTitle title="leads" buttonOn={false} />
      <LeadContent
        keyword={state.keyword}
        leads={state.leads}
        editClick={handleEditAction}
        keywordChange={keywordChange}
        filterLeads={filterLeads}
        resetKeyword={resetKeyword}
        alert={state.alert}
        lead_url={state.lead_url}
      />

      <EditLead
        showEditModal={state.showEditModal}
        lead={state.lead}
        loader={state.loader}
        handleClose={handleClose}
        add_comment={state.new_comment}
        newComment={newComment}
        updateLead={updateLead}
        updateStatus={updateStatus}
      ></EditLead>
      {state.alert.show && (
        <AlertMessage
          show={state.alert.show}
          message={state.alert.message}
          dismiss={dissmissNotify}
          type={state.alert.varient}
          header=""
        />
      )}
    </div>
  );
}
export default withTranslation()(withRouter(Leasds));

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import companyName from '../../../store/mobxStore/companyName'
import { PageTitle } from '../../components/common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from '../../shared/Spinner'
import style from "../../components/ProductWallet/ewallet.module.scss";
import ProductWalletservice from '../../../service/ProductWallet/Productwallet'
import Tiles from '../../components/ProductWallet/Tiles'
import UserStore from '../../../store/UserStore'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import moment from "moment";
import Content from '../../components/ProductWallet/Content'
import ModalForm from '../../components/ProductWallet/ModalForm'
import { Modal } from 'react-bootstrap'
import GadgetmodalForm from '../../components/ProductWallet/GadgetmodalForm'
import AdminwalletModalForm from '../../components/ProductWallet/AdminwalletModalForm'
function Productwallet(props) {
    const Currency = useSelector((state) => state.curr);
    const service = new ProductWalletservice();
    const [state, setstate] = useState({
        Modal: {
            fundTransfer: false,
            gadgetTransfer: false,
            adminTRansfer: false

        },
        loading: true,
        tilesData: [],
        repurchase_status: "yes",
        balance: 0,
        userspecialAccess:false,
        DataTables: {
            key: UserStore.checkUserEarnings ? "earning" : "statement",
            statement: {
                totalRows: 0,
                data: [],
                pending: true,
                perPage: 10,
                inPage: 1

            },
            history: {
                totalRows: 0,
                data: [],
                pending: true,
                perPage: 10,
                inPage: 1,
                startDate: moment().subtract(29, "days"),
                endDate: moment(),
                direction: "",
            },
            wallet: {
                totalRows: 0,
                data: [],
                pending: true,
                perPage: 10,
                inPage: 1,
            },
            earnings: {
                totalRows: 0,
                data: [],
                pending: true,
                perPage: 10,
                inPage: 1,
                startDate: moment().subtract(29, "days"),
                endDate: moment(),
                direction: "",
                category: [],
            },
        }
    })
    const [loader, setLoader] = useState({
        EarningsTable: {
            search: false,
            reset: false,
            excel: false,
            csv: false,
            print: false,
        },
        HistoryTable: {
            search: false,
            reset: false,
        },
    });
    const [locationKeys, setLocationKeys] = useState([]);
    const history = useHistory();
    const { t } = useTranslation();
    const loadData = () => {
        service.ProductWallet().then((res) => {
            if (res.status) {
                setstate((prev) => ({
                    ...prev,
                    tilesData: res.data.cashwallet_tile,
                    repurchase_status: res.data.repurchase_status,
                    balance: res.data.balance,
                    userspecialAccess:res.data.userSpecialAccess,
                    loading: false
                }))
            }
        })
        getstatementTable();
        getHistoryTable();
        // getWalletTable();
        getUserEarningsTable();
    }
    useEffect(() => {
        history.listen((location) => {
            if (history.action === "PUSH") {
                setLocationKeys([location.key]);
                if (location.pathname === "/dashboard") {
                    UserStore.checkUserEarnings = false;
                }
            }
            if (history.action === "POP") {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                } else {
                    setLocationKeys((keys) => [location.key, ...keys]);
                    UserStore.checkUserEarnings = false;
                }
            }
        });
        loadData()

    }, [])     //props, locationKeys, Currency.currentCurr
    const getstatementTable = (inPage = state.DataTables.statement.inPage, perPage = state.DataTables.statement.perPage) => {
        let start = (inPage - 1) * perPage
        service.statement(start, perPage).then((res) => {
            if (res.status) {
                setstate((prevState) => ({
                    ...prevState,
                    DataTables: {
                        ...prevState.DataTables,
                        statement: {
                            ...prevState.DataTables.statement,
                            totalRows: res.data.count,
                            data: res.data.table_data,
                            pending: false,
                        },
                    },
                }));
            } else {
                history.push("/logout");
            }
        })
    }


    const changeStatementTable = (inPage, perPage) => {
        setstate((prevState) => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                statement: {
                    ...prevState.DataTables.statement,
                    perPage: perPage,
                    inPage: inPage,
                    // pending     : false
                },
            },
        }));
        getstatementTable(inPage, perPage);
    };

    const getHistoryTable = (
        inPage = state.DataTables.history.inPage,
        perPage = state.DataTables.history.perPage,
        direction = "",
        startDate = moment().startOf("month").format("MMMM D, YYYY"),
        endDate = moment().format("MMMM D, YYYY"),
        order = "",
        keys = ""
    ) => {
        let start = (inPage - 1) * perPage;
        service
            .history(start, perPage, direction, startDate, endDate, order, keys)
            .then((res) => {
                if (res.status) {
                    setLoader({
                        EarningsTable: {
                            search: false,
                            reset: false,
                            excel: false,
                            csv: false,
                            print: false,
                        },
                        HistoryTable: {
                            search: false,
                            reset: false,
                        },
                    });
                    setstate((prevState) => ({
                        ...prevState,
                        DataTables: {
                            ...prevState.DataTables,
                            history: {
                                ...prevState.DataTables.history,
                                totalRows: res.data.count,
                                data: res.data.table_data,
                                pending: false,
                            },
                        },
                    }));
                } else {
                    props.history.push("/logout");
                }
            });
    };

    const changeHistoryTable = (
        inPage,
        perPage,
        start,
        end,
        dierection,
        colKey,
        key
    ) => {
        setstate((prevState) => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                history: {
                    ...prevState.DataTables.history,
                    perPage: perPage,
                    inPage: inPage,
                    // pending     : true,
                    startDate: start,
                    endDate: end,
                    direction: dierection,
                },
            },
        }));
        getHistoryTable(inPage, perPage, dierection, start, end, colKey, key);
    };

    // const getWalletTable = (
    //     inPage = state.DataTables.wallet.inPage,
    //     perPage = state.DataTables.wallet.perPage
    // ) => {
    //     let start = (inPage - 1) * perPage;
    //     service.wallet(start, perPage).then((res) => {
    //         if (res.status) {
    //             setstate((prevState) => ({
    //                 ...prevState,
    //                 DataTables: {
    //                     ...prevState.DataTables,
    //                     wallet: {
    //                         ...prevState.DataTables.wallet,
    //                         totalRows: res.data.count,
    //                         data: res.data.table_data,
    //                         pending: false,
    //                     },
    //                 },
    //             }));
    //         } else {
    //             props.history.push("/logout");
    //         }
    //     });
    // };
    // const changeWalletTable = (inPage, perPage) => {
    //     setstate((prevState) => ({
    //         ...prevState,
    //         DataTables: {
    //             ...prevState.DataTables,
    //             wallet: {
    //                 ...prevState.DataTables.wallet,
    //                 perPage: perPage,
    //                 inPage: inPage,
    //                 // pending     : true
    //             },
    //         },
    //     }));
    //     getWalletTable(inPage, perPage);
    // };


    const getUserEarningsTable = (
        inPage = state.DataTables.earnings.inPage,
        perPage = state.DataTables.earnings.perPage,
        direction = "",
        startDate = moment().startOf("month").format("MMMM D, YYYY"),
        endDate = moment().format("MMMM D, YYYY"),
        order = "",
        keys = ""
    ) => {
        let start = (inPage - 1) * perPage;

        service.earnigs(start, perPage, direction, startDate, endDate, order, keys)
            .then((res) => {
                if (res.status) {
                    setLoader({
                        EarningsTable: {
                            search: false,
                            reset: false,
                            excel: false,
                            csv: false,
                            print: false,
                        },
                        HistoryTable: {
                            search: false,
                            reset: false,
                        },
                    });

                    setstate((prevState) => ({
                        ...prevState,
                        DataTables: {
                            ...prevState.DataTables,
                            earnings: {
                                ...prevState.DataTables.earnings,
                                totalRows: res.data.count,
                                data: res.data.table_data,
                                pending: false,
                                category: res.data.category,
                            },
                        },
                    }));
                } else {
                    props.history.push("/logout");
                }
            });
    };

    const changeEarningsTable = (
        inPage,
        perPage,
        start,
        end,
        dierection,
        colKey,
        key
    ) => {
        setstate((prevState) => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                earnings: {
                    ...prevState.DataTables.earnings,
                    perPage: perPage,
                    inPage: inPage,
                    // pending     : true,
                    startDate: start,
                    endDate: end,
                    direction: dierection,
                },
            },
        }));
        getUserEarningsTable(inPage, perPage, dierection, start, end, colKey, key);
    };

    const tabChangeHandler = () => {
        setstate((prevState) => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                statement: {
                    ...prevState.DataTables.statement,
                    perPage: 10,
                    inPage: 1,
                },
                history: {
                    ...prevState.DataTables.history,
                    perPage: 10,
                    inPage: 1,
                },
                wallet: {
                    ...prevState.DataTables.wallet,
                    perPage: 10,
                    inPage: 1,
                },
                earnings: {
                    ...prevState.DataTables.earnings,
                    perPage: 10,
                    inPage: 1,
                },
            },
        }));

        getstatementTable();
        getHistoryTable();
        // getWalletTable();
        getUserEarningsTable();
    };
    const repurchase = () => {
        return state.purchase_wallet === "yes" ? true : false;
    };
    const paginationHandler = {
        statement: changeStatementTable,
        history: changeHistoryTable,
        // wallet: changeWalletTable,
        earnings: changeEarningsTable,
    };
    const commissionTransfer = () => {
        setstate((prev) => ({
            ...prev,
            Modal: {
                ...prev.Modal,
                fundTransfer: true
            }
        }))
    }
    const gadgetwalletTransfer = () => {
        setstate((prev) => ({
            ...prev,
            Modal: {
                ...prev.Modal,
                gadgetTransfer: true
            }
        }))
    }
    const adminproductwalletTransfer = () => {
        console.log('=================')
        setstate((prev) => ({
            ...prev,
            Modal: {
                ...prev.Modal,
                adminTRansfer: true
            }
        }))
    }
    const checkUserSpecialStatus=()=>{
        return state.userspecialAccess===true?true:false
    }
    const ButtonGroup = [
        {
            name: "adminproductwalletTransfer",
            click: adminproductwalletTransfer
        },
        ...(checkUserSpecialStatus()
        ?[
            {
                name: "cashwalletTransfer",
                click: commissionTransfer
            },
            {
                name: "gadgetwalletTransfer",
                click: gadgetwalletTransfer
            },
        ]
        :[]),
        
       
    ]


    const handleClose = () => {
        // setCheckout(false);
        // setEwalletAmount((prevState) => ({
        //   ...prevState,
        //   amount: "",
        // }));
        setstate((prevState) => ({
            ...prevState,
            Modal: {
                ...prevState.Modal,
                fundTransfer: false,
            },
        }));
    };
    const gadgetModalClose = () => {
        setstate((prev) => ({
            ...prev,
            Modal: {
                gadgetTransfer: false
            }
        }))
    }

    const adminModalClose = () => {
        setstate((prev) => ({
            ...prev,
            Modal: {
                adminTRansfer: false
            }
        }))
    }
    console.log('modalshow',state.Modal.adminTRansfer)
    return (
        <div className={`h-100`}>
            <Helmet>
                <title>
                    {companyName.companyName} | {t('Sidemenu.productwallet')}

                </title>
            </Helmet>
            <PageTitle title="productwallet" buttonOn={true} group={ButtonGroup} />
            {state.loading ? (
                <Spinner />
            ) : (
                <div className={style.MainContainer}>
                    {state.tilesData && (
                        <Tiles
                            tilesData={state.tilesData}
                            repurchase_status={state.purchase_wallet}
                        />
                    )}
                    <Content
                        tableData={state.DataTables}
                        changeHandler={tabChangeHandler}
                        paginationHandler={paginationHandler}
                        repurchase_status={state.purchase_wallet}
                        loader={loader}
                    />
                    <Modal
                        show={state.Modal.fundTransfer}
                        onHide={handleClose}
                        dialogClassName={`${style.ModalDialog
                            } animate__animated animate__fadeInRight ${state.Modal.fundTransfer
                                ? "animate__fadeInRight"
                                : "animate__fadeOutRight"
                            } FundTransferModal`}
                    >
                        <Modal.Header closeButton bsPrefix={style.ModalHeader}>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={style.ModalBodyHeader}>
                                <h3>{t("ewallet.cashwalletFundTransfer")}</h3>
                            </div>
                            <ModalForm
                                balance={state.balance}
                                transFee={state.TransactionFee}
                                submitSuccess={loadData}
                                handleClose={handleClose}
                            />
                        </Modal.Body>
                    </Modal>
                    {/* ----------//gadgetwalletmodel */}
                    <Modal
                        show={state.Modal.gadgetTransfer}
                        onHide={gadgetModalClose}
                        dialogClassName={`${style.ModalDialog
                            } animate__animated animate__fadeInRight ${state.Modal.gadgetTransfer
                                ? "animate__fadeInRight"
                                : "animate__fadeOutRight"
                            } FundTransferModal`}
                    >
                        <Modal.Header closeButton bsPrefix={style.ModalHeader}>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={style.ModalBodyHeader}>
                                <h3>{t("ewallet.gadgetwalletFundTransfer")}</h3>
                            </div>
                            <GadgetmodalForm
                                balance={state.balance}
                                transFee={state.TransactionFee}
                                submitSuccess={loadData}
                                handleClose={gadgetModalClose}
                            />
                        </Modal.Body>
                    </Modal>

                    {/* adminproductwalletTRansfer */}
                    <Modal
                        show={state.Modal.adminTRansfer}
                        onHide={adminModalClose}
                        dialogClassName={`${style.ModalDialog
                            } animate__animated animate__fadeInRight ${state.Modal.adminTRansfer
                                ? "animate__fadeInRight"
                                : "animate__fadeOutRight"
                            } FundTransferModal`}
                    >
                        <Modal.Header closeButton bsPrefix={style.ModalHeader}>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={style.ModalBodyHeader}>
                                <h3>{t("ewallet.adminwalletFundTransfer")}</h3>
                            </div>
                            <AdminwalletModalForm
                                balance={state.balance}
                                transFee={state.TransactionFee}
                                submitSuccess={loadData}
                                handleClose={adminModalClose}
                            />
                        </Modal.Body>
                    </Modal>
                </div>
            )}

        </div>
    )
}

export default Productwallet
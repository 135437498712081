import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import style from "./epin.module.scss";
import { useTranslation } from "react-i18next";
import { Filter, DropDown, DataTable } from "./";
import { CurrencyDecimalFormat } from "../../helper";
import AlertMessage from "../common/AlertMessage";
//services
import EpinService from "../../../service/epin/epin";

function EpinList(props) {
  //redux value get the current currency
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation(); // translation
  const [refundSuccess,setRefundSuccess] = useState(false);
  //coloumns
  //state
  const [state, setState] = useState({
    selectedValues: {
      epin: [],
      amount: [],
    },
    editLoader: false,
    selectedStatus: "active",
    columns: [],
    Newamount: [],
    notify: {
      show: false,
      type: "",
      message: "",
      header: "",
    },
  });

  const dissmissNotify = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: false,
      },
    }));
  };

  const refund_pin = (pin_id, action) => {
    let service = new EpinService();
    let formdata = {
      action: action,
      delete_id: pin_id,
    };

    //success and error
    service.refundAmount(formdata).then((res) => {
      if (res.status) {
        setRefundSuccess(true);
        setState({
          ...state,
          notify: {
            show: true,
            type: "success",
            message: "Refund Successful",
          },
        });
        props.getEpinList();
        props.loaddata();
      } else {
        setRefundSuccess(false);
        setState({
          ...state,
          notify: {
            show: true,
            type: "danger",
            message: "Refund Unsuccessful",
          },
        });
      }
    });
   
  };

  //active columns
  useEffect(() => {
    const Columns = [
      {
        name: t("Common.epin").toUpperCase(),
        selector: (row) => row.pin_number,
        //   width : "30%"
      },
      {
        name: t("Common.amount").toUpperCase(),
        selector: (row) => row.amount,
        cell: (row) => (
          <span className={`${style.badgeblue} ${style.badge}`}>
            {Currency.currentCurr}{" "}
            {CurrencyDecimalFormat(
              row.amount * Currency.value,
              Currency.precision
            )}
          </span>
        ),
      },
      {
        name: t("Common.balanceAmount").toUpperCase(),
        selector: (row) => row.balance_amount,
        sortable: true,
        cell: (row) => (
          <span className={`${style.badgegreen} ${style.badge}`}>
            {Currency.currentCurr}{" "}
            {CurrencyDecimalFormat(
              row.balance_amount * Currency.value,
              Currency.precision
            )}
          </span>
        ),
      },
      {
        name: t("Common.status").toUpperCase(),
        selector: (row) => row.status,
        sortable: true,
        cell: (row) => (
          <span className={`${style.status} ${style[row.status]}`}>
            {t("Common." + row.status)}
          </span>
        ),
      },
      {
        name: t("Common.expireDate").toUpperCase(),
        selector: (row) => row.expiry_date,
        sortable: true,
      },
      {
        name: t("Common.action").toUpperCase(),
        button: true,
        selector: (row) => row.epin_id,
        sortable: true,
        cell: (row) => (
          <span>
            {row.refund === "na" ? (
              <span>NA</span>
            ) : (
              <button
                className={style.refundText}
                onClick={() => refund_pin(row.epin_id, row.refund)}
              >
                {t("Common.refund")}&nbsp;
                <i className="fa fa-undo"></i>
              </button>
            )}
            {/* <i className="fa fa-revert"></i> */}
          </span>
        ),
      },
    ];
    setState((prev) => ({
      ...prev,
      columns: Columns,
    }));
    return()=>{
      setRefundSuccess(false)
    }
  }, [Currency, t(),refundSuccess]);
  const { selectedValues, columns, Newamount } = state;
  //life cycle
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedValues: {
        ...prevState.selectedValues,
        epin: [],
        amount: [],
      },
    }));
  }, [Currency]);
  useEffect(() => {
    if (props.data.amounts) {
      //change the amount
      let Newamounts = props.data.amounts.map((amount) => {
        return {
          amount: `${Currency.currentCurr} ${(
            amount.amount * Currency.value
          ).toFixed(Currency.precision)}`,
          id: amount.id,
          defaultAmount: amount.amount,
        };
      });
      setState((prev) => ({
        ...prev,
        Newamount: Newamounts,
      }));
    }
  }, [Currency, props.data.amounts]);

  //change status
  const changeStatus = (key) => {
    setState((prevState) => ({
      ...prevState,
      selectedStatus: key,
    }));
  };

  //reset button submit
  const resetValue = () => {
    setState((prevState) => ({
      ...prevState,
      selectedValues: {
        epin: [],
        amount: [],
      },
      selectedStatus: "active",
    }));
    props.reset();
  };

  //flter submit button click
  const submitHandler = () => {
    let epinsList = "";
    state.selectedValues.epin.forEach((element) => {
      if (epinsList) {
        epinsList += "&epins[]=" + element.pin_numbers;
      } else {
        epinsList = element.pin_numbers;
      }
    });
    let amountLst = "";
    state.selectedValues.amount.forEach((element) => {
      if (amountLst) {
        amountLst += "&amounts[]=" + element.defaultAmount;
      } else {
        amountLst = element.defaultAmount;
      }
    });
    if (
      state.selectedStatus === "used_expired" ||
      state.selectedStatus === "deleted"
    ) {
      let NewCol = columns;

      NewCol.splice(0, 1);
      setState((prev) => ({
        ...prev,
        columns: NewCol,
      }));
    }
    props.listFilter(
      props.data.perPage,
      props.data.inPage,
      "",
      "",
      epinsList,
      amountLst,
      state.selectedStatus
    );
  };
  //get the filter value
  const filterValue = (e, name) => {
    setState((prevState) => ({
      ...prevState,
      selectedValues: {
        ...prevState.selectedValues,
        [name]: e,
      },
    }));
  };
  //handle row per change
  const handlePerRowsChange = (perPage, inPage) => {
    props.listFilter(perPage, inPage, "", "", "", "", state.selectedStatus);
  };
  const handlePageChange = (inPage) => {
    let epinsList = "";
    state.selectedValues.epin.forEach((element) => {
      if (epinsList) {
        epinsList += "&epins[]=" + element.pin_numbers;
      } else {
        epinsList = element.pin_numbers;
      }
    });
    let amountLst = "";
    state.selectedValues.amount.forEach((element) => {
      if (amountLst) {
        amountLst += "&amounts[]=" + element.defaultAmount;
      } else {
        amountLst = element.defaultAmount;
      }
    });
    props.listFilter(
      props.data.perPage,
      inPage,
      "",
      "",
      epinsList,
      amountLst,
      state.selectedStatus
    );
  };
  return (
    <div>
      <Form className="align-items-center">
        <Form.Row>
          <Col xs="auto">
            <Filter
              option={props.data.epins}
              placeHolder="epin"
              displayKey="pin_numbers"
              selectedValue={selectedValues.epin}
              handler={filterValue}
              search={props.search}
            />
          </Col>
          <Col xs="auto">
            <Filter
              option={Newamount}
              placeHolder="amount"
              displayKey="amount"
              selectedValue={selectedValues.amount}
              handler={filterValue}
            />
          </Col>
          <Col xs="auto">
            <DropDown
              changeHandler={changeStatus}
              status={state.selectedStatus}
            />
          </Col>
          <Col xs="auto">
            <div className={`${style.filterButtons}`}>
              <Button
                variant="info"
                className={style.padding5}
                onClick={submitHandler}
                disabled={props.loader.search}
              >
                {props.loader.search ? (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                ) : (
                  ""
                )}

                {t("Button.search")}
              </Button>

              <Button
                className={style.padding5}
                onClick={resetValue}
                disabled={props.loader.reset}
              >
                {props.loader.reset ? (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                ) : (
                  ""
                )}

                {t("Button.reset")}
              </Button>
            </div>
          </Col>
        </Form.Row>
      </Form>
      <div className={style.tables}>
        <DataTable
          columns={columns}
          data={props.data.data}
          totalRows={props.data.totalRows}
          pending={props.data.pending}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
        />
      </div>
      {state.notify.show && (
        <AlertMessage
          show={state.notify.show}
          message={state.notify.message}
          dismiss={dissmissNotify}
          type={state.notify.type}
          header={state.notify.header}
        />
      )}
    </div>
  );
}

export default EpinList;

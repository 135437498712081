import {useState,useEffect} from 'react';

export const PasswordValidation=({password,policy})=> {
    const [validLength, setValidLength] = useState(null);
    const [hasNumber, setHasNumber] = useState(null);
    const [upperCase, setUpperCase] = useState(null);
    const [lowerCase, setLowerCase] = useState(null);
    const [specialChar, setSpecialChar] = useState(null);
    const [mixedCase, setMixedCase] = useState(null);
    useEffect(() => {
        console.log("policy",policy)
        setValidLength(password.length >= policy.min_length ? true : false);
        if(policy.mixedcase && policy.mixedcase){
            setLowerCase((/[a-z]/.test(password))?true:false)
            setMixedCase((/(?=.*[a-z])(?=.*[A-Z])/.test(password))?true:false)
        }
        if(policy.mixedcase && policy.mixedcase){
            setUpperCase((/[A-Z]/.test(password))?true:false)
        }
        if(policy.sp_char){
            if(password){
                var format = /^[A-Za-z0-9]+$/;
                setSpecialChar(!format.test(password)?true:false)
            }
        }
        if(policy.number){
            setHasNumber((/[0-9]/.test(password))?true:false)
        }
    }, [password,policy]);
    console.log("mixedCase",mixedCase)
    return [validLength, hasNumber, upperCase, lowerCase, specialChar,mixedCase];
}

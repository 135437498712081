import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CustomButton } from '../common';
import {useTranslation} from 'react-i18next';
function PaypalPayment(props) {
 
    const [paid, setPaid] = useState(false);
    const [error, setError] = useState(null);
    const   paypalRef =useRef();
    const {t} = useTranslation();
          //
    const passPaymentDetails = useCallback((id)=>{
      props.getPaypalData(id);
    },[props]);
    //initialise the paypal config
    useEffect(() => {
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [
                  {
                    description: "Your description",
                    amount: {
                      currency_code: "USD",
                      value: props.amount,
                    },
                  },
                ],
              });
            },
            onApprove: async (data, actions) => {
               const order = await actions.order.capture();
              setPaid(true);
              passPaymentDetails({
                orderID : data.orderID,
                payerID : data.payerID
              });
            },
            onError: (err) => {
              setError(err)
            },
          })
          .render(paypalRef.current);
      }, [props.amount,passPaymentDetails]);

     const clickHandler = () =>{
     
      props.checkout && props.checkout(false)
     }

      // If the payment has been made
    if (paid) {
      return <CustomButton variant="primary" type="submit" onClick={clickHandler}>
                  {t('Button.finish')}  
                  
              </CustomButton>;
    }

    // If any error occurs
    if (error) {
      return <div>Error Occurred in processing payment.! Please try again.</div>;
     // return <div>Error Occurred in processing payment.! Please try again with amount</div>;
    }
    return (
        <div className="p-2">
            <div ref={paypalRef} />
        </div>
    )
}

export default PaypalPayment

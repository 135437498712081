import React, { useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import style from "./payin.module.scss";
import { useTranslation } from "react-i18next";
const PayoutToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    className={style.selectedCatgory}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {/* Render custom icon here */}
    {children}
    <span className={style.caretIcon}></span>
  </span>
));
function DropDown({ handler, loader }) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    selectedEvent: "approved",
    selectedWallet: "1",
  });
  const { selectedEvent, selectedWallet } = state;

  //dropdown Data
  const DropDownData = ["pending", "approved", "rejected"];
  const walletTypes = ["0", "1", "2"];

  //dropdownChnage handler
  const ChangeHandler = (eventKey, type) => {
    console.log("eventKey,type", eventKey, type);
    if (type === "event") {
      setState((prev) => ({
        ...prev,
        selectedEvent: eventKey,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        selectedWallet: eventKey,
      }));
    }
  };
  console.log("selectedwallet", selectedWallet);
  //reset value
  const resetValue = () => {
    loader.reset = true;
    setState({ selectedEvent: "approved", selectedWallet: "1" });
    handler("approved");
  };

  //serch button click
  const submitHandler = () => {
    loader.search = true;

    handler(state.selectedEvent, state.selectedWallet);
  };

  return (
    <div className={style.filterSection}>
      <Dropdown
        className={`${style.filterDropDown}`}
        onSelect={(event) => {
          ChangeHandler(event, "event");
        }}
      >
        <Dropdown.Toggle as={PayoutToggle}>
          {t("Common.status") + " - " + t("Common." + selectedEvent)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {DropDownData.map((items, index) => {
            return (
              <Dropdown.Item key={index} eventKey={items}>
                {t("Common.status") + " - " + t("Common." + items)}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      <div className={`${style.filterButtons}`}>
        <Button
          variant="info"
          className={style.padding5}
          onClick={submitHandler}
          disabled={loader.search}
        >
          {loader.search ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )}

          {t("Button.search")}
        </Button>
        <Button
          className={style.padding5}
          onClick={resetValue}
          disabled={loader.reset}
        >
          {loader.reset ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )}

          {t("Button.reset")}
        </Button>
      </div>
    </div>
  );
}

export default DropDown;
